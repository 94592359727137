import { Discount, PremiumDetails } from "raci-new-business-pet-clientproxy";
import { useSessionStatePrefix } from "raci-react-library";
import { useState } from "react";

export const QUOTE_SESSION_KEY = "QUOTE";

export interface QuoteState {
  quoteNumber: string;
  excess: number;
  premium: PremiumDetails;
  discounts: Discount[];
  hasTlcCover: boolean;
}

export const useQuoteSession = <T extends QuoteState>(): [T, (newValue: T) => void] => {
  const sessionKeyPrefix = useSessionStatePrefix();
  const storageKey = `${sessionKeyPrefix}${QUOTE_SESSION_KEY}`;
  const state = JSON.parse(sessionStorage.getItem(storageKey) || "{}") as T;
  const [, setState] = useState<T>(state);
  const setValue = (newValue: T) => {
    const copy = structuredClone(newValue) as T;
    sessionStorage.setItem(
      storageKey,
      JSON.stringify({
        ...copy,
      }),
    );
    setState(copy);
  };

  return [state, setValue];
};

export default useQuoteSession;
