import { selector } from "recoil";
import { petBreedsAtom } from "../atoms";

export const catBreedsSelector = selector({
  key: "catBreeds",
  get: ({ get }) => get(petBreedsAtom).filter(({ petTypeId }) => petTypeId === "4000001" /* cat id type*/),
});

export const dogBreedsSelector = selector({
  key: "dogBreeds",
  get: ({ get }) => get(petBreedsAtom).filter(({ petTypeId }) => petTypeId === "4000002" /* dog id type */),
});
