import { EmailValidationPattern, PaymentFrequency, YesNo, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useApiClient } from "../../../../shared/hooks/useApiClient";
import useQuoteSession from "../../../../shared/hooks/useQuoteSession";
import {
  PURCHASE_START_DATE_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { YourQuoteFormProps, YourQuoteState } from "../../types";

export const useYourQuote = (): YourQuoteFormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [sessionState, setSessionState] = useSessionState<YourQuoteState>();
  const [quoteState, setQuoteState] = useQuoteSession();

  const form = useForm<YourQuoteState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  const onSubmit = async (newValues: YourQuoteState) => {
    try {
      setBackdrop(true);
      setSessionState({ ...newValues, isCompleted: true });
      navigate(PURCHASE_START_DATE_PAGE_URL);
    } catch (exception) {
    } finally {
      setBackdrop(false);
    }
  };

  const onEmailQuote = async (email: string) => {
    try {
      if (new RegExp(EmailValidationPattern).test(email)) {
        setBackdrop(true);
        // TODO: Show the PremiumChangeDialog if the response comes back with an updated premium flag
        await apiClient.emailQuote({ email: email });
      }
    } catch (exception) {
    } finally {
      setBackdrop(false);
    }
  };

  const onChangeExcess = async (value: number) => {
    try {
      setBackdrop(true);
      const request = {
        excessAndCover: {
          excess: value,
        },
      };
      const response = await apiClient.updateQuote(request);
      setQuoteState(response.result);
      setSessionState({ ...sessionState, excess: value });
    } catch (exception) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } finally {
      setBackdrop(false);
    }
  };

  const onChangeTlcCover = async (value: boolean) => {
    try {
      setBackdrop(true);
      const request = {
        excessAndCover: {
          requestedTlcCover: value,
        },
      };
      const response = await apiClient.updateQuote(request);
      setQuoteState(response.result);
      setSessionState({ ...sessionState, tlcCover: value ? YesNo.Yes : YesNo.No });
    } catch (exception) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    onEmailQuote,
    onChangeExcess,
    onChangeTlcCover,
    quoteNumber: quoteState.quoteNumber,
    premiumDetails: quoteState.premium,
    discounts: quoteState.discounts,
    defaultPaymentFrequency: PaymentFrequency.Annual,
    // TODO: This 200 (same as AdjustPayment) should be returned from the Backend
    defaultExcess: sessionState.excess ?? 200,
    defaultTlcCover: sessionState.tlcCover ?? YesNo.No,
  };
};

export default useYourQuote;
