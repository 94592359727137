import { Button, Grid, Link } from "@mui/material";
import { ProductDisclosureStatementLink } from "raci-react-library";
import { StyledGrid, StyledTypography } from "./styled";
import { ImportantInformationFormProps } from "./types";

export const ImportantInformationForm = ({ pdsUrl, privacyUrl, redirectToNextPage }: ImportantInformationFormProps) => (
  <Grid container justifyContent="center" alignItems="center">
    <StyledGrid container>
      <Grid item xs={12}>
        <StyledTypography variant="body1">
          You must answer our questions honestly, accurately and to the best of your knowledge. This duty applies to you
          and anyone else insured under the policy. If you answer for another person, we'll treat your answers as
          theirs. Your duty continues until we insure you. If you don't meet this duty, we may cancel your policy or
          treat as if it never existed. Your claim may also be rejected or not paid in full. This insurance is a
          consumer insurance contract.
        </StyledTypography>
        <StyledTypography id="bodytexts">
          By selecting <b>Continue</b>, you agree that we've shown you the
          <ProductDisclosureStatementLink showAbbreviation={false} showInline={true} url={pdsUrl} preamble={" "} />. You
          also agree with our{" "}
          <Link href={privacyUrl} target="_blank">
            Privacy Policy.
          </Link>
        </StyledTypography>
      </Grid>

      <Grid item xs={12}>
        <Button
          id="button"
          type="button"
          data-testid="continue"
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => redirectToNextPage()}
        >
          Continue
        </Button>
      </Grid>
    </StyledGrid>
  </Grid>
);

export default ImportantInformationForm;
