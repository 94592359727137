import { SessionState, YesNo } from "raci-react-library";
import { UseFormReturn } from "react-hook-form";

export enum PetGender {
  Male = "Male",
  Female = "Female",
}

export interface MoreAboutYourPetState extends SessionState {
  petGender: PetGender;
  petSterilised: YesNo;
}

export interface MoreAboutYourPetFormProps {
  form: UseFormReturn<MoreAboutYourPetState>;
  onSubmit: (newValues: MoreAboutYourPetState) => Promise<void>;
  petName: string;
}
