import { faPhoneFlip } from "@fortawesome/free-solid-svg-icons";
import { FormLabel, Grid, Typography } from "@mui/material";
import { EMPTY_URL, InsurancePhoneNumber, MyRacAccountDisplay, PhoneNumberLink } from "raci-react-library";
import { StyledDivider, StyledFadeWiggleIcon, StyledFinePrint, StyledGrid } from "../../styled";

export interface UnsuccessfulConfirmationProps {
  policyNumber?: string;
}

export const UnsuccessfulConfirmation = ({ policyNumber }: UnsuccessfulConfirmationProps) => (
  <StyledGrid container>
    <Grid item xs={12} sx={{ marginBottom: "24px" }}>
      <StyledFadeWiggleIcon icon={faPhoneFlip} size="7x" />
    </Grid>
    <Grid item xs={12} sx={{ marginBottom: "32px" }}>
      <Typography variant="h2" id="header" data-testid="header">
        Please call us
      </Typography>
    </Grid>
    <Grid item xs={12} sx={{ marginBottom: "32px" }}>
      <FormLabel>We couldn't process your payment.</FormLabel>
    </Grid>
    <Grid item xs={12} sx={{ marginBottom: "32px" }}>
      <FormLabel>But we've issued your policy and emailed your policy documents.</FormLabel>
    </Grid>
    <Grid item xs={12} sx={{ marginBottom: "32px" }}>
      <FormLabel>
        Please call us on <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id="confirmation-phonenumberlink" /> to
        finalise your payment.
      </FormLabel>
    </Grid>
    <Grid item xs={12} data-testid="policyNumberLabel">
      <StyledFinePrint>Your policy number is {policyNumber}.</StyledFinePrint>
      <StyledDivider />
    </Grid>
    <MyRacAccountDisplay
      myRacLogInUrl={process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL}
      homePageUrl={process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL}
    />
  </StyledGrid>
);

export default UnsuccessfulConfirmation;
