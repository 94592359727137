import { useGetPreviousPageSessionState, useGetSessionState, useSessionStateInitialiser } from "raci-react-library";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { AboutYourPetState } from "../../../views/AboutYourPet/types";
import { YourPetState } from "../../../views/YourPet/types";
import { YourQuoteState } from "../../../views/YourQuote/types";
import { SESSION_KEY_PREFIX } from "../../constants";
import {
  PurchaseRoutes,
  QuoteRoutes,
  RouteInformation,
  allRoutes,
  quoteRoutes,
  quoteToPurchaseRoutes,
} from "../routes.config";

interface InternalRouteStates {
  hasReachedPurchaseFlow: boolean;
}

const sessionStorageKey = `${SESSION_KEY_PREFIX}Internal_Route_State`;
const defaultInternalRouteStates: InternalRouteStates = JSON.parse(
  sessionStorage.getItem(sessionStorageKey) ?? JSON.stringify({ hasReachedPurchaseFlow: false }),
) as InternalRouteStates;

const internalRouteStates = atom<InternalRouteStates>({
  key: "InternalRouteStates",
  default: defaultInternalRouteStates,
});

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesResults {
  isQuoteOrPurchaseFlow: boolean;
  hasReachedPurchaseFlow: boolean;
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  showPurchaseFlow: () => void;
  isInQuoteFlow: boolean;
  isQuoteCompleted: boolean;
  navigateToPreviousStep?: () => void;
  canNavigateToPreviousStep: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialiseSessionStates = useSessionStateInitialiser();

  useEffect(() => {
    initialiseSessionStates(
      allRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);

  const { path: previousPageUrl } = useGetPreviousPageSessionState();
  const [internalState, setInternalState] = useRecoilState(internalRouteStates);
  const { hasReachedPurchaseFlow } = internalState;
  const isQuoteOrPurchaseFlow = allRoutes.filter((item) => item.path === location.pathname).length > 0;
  const isInQuoteFlow = quoteRoutes.filter((item) => item.path === location.pathname).length > 0;
  const { isCompleted: isQuotePageCompleted } = useGetSessionState<AboutYourPetState>(QuoteRoutes.AboutYourPet);
  const yourPetState = useGetSessionState<YourPetState>(QuoteRoutes.YourPet);
  const yourQuoteState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);

  const formRoutes = useMemo(() => {
    if (yourQuoteState.isCompleted) {
      return quoteToPurchaseRoutes;
    } else {
      return quoteRoutes;
    }
  }, [yourQuoteState.isCompleted]);

  const isActiveFlow = formRoutes.some((item) => item.path === location.pathname);

  const steps: StepInformation[] = useMemo(() => {
    if (!isActiveFlow) return [];

    return formRoutes.map((option) => {
      if (option.key === QuoteRoutes.AboutYourPet && yourPetState.isCompleted) {
        return {
          name: `About ${yourPetState.petName}`,
          path: option.path,
        };
      }

      if (option.key === PurchaseRoutes.MoreAboutYourPet && yourPetState.isCompleted) {
        return {
          name: `More about ${yourPetState.petName}`,
          path: option.path,
        };
      }

      return { name: option.name, path: option.path };
    }, []);
  }, [formRoutes, isActiveFlow, yourPetState.isCompleted, yourPetState.petName]);

  const activeStepIndex = isActiveFlow ? steps.findIndex((item) => item.path === location.pathname) : undefined;

  const totalStepCount = isActiveFlow ? steps.length : undefined;

  const showPurchaseFlow = useCallback(() => {
    if (!internalState.hasReachedPurchaseFlow) {
      const newState = { ...internalState, hasReachedPurchaseFlow: true };
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(newState));
      setInternalState(newState);
    }
  }, [setInternalState, internalState]);

  const navigateToPreviousStep = useMemo(() => {
    return isQuoteOrPurchaseFlow && previousPageUrl && (activeStepIndex ?? 0) > 0
      ? () => navigate(previousPageUrl, { replace: true })
      : undefined;
  }, [navigate, isQuoteOrPurchaseFlow, activeStepIndex, previousPageUrl]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep &&
    steps.indexOf(quoteToPurchaseRoutes[quoteToPurchaseRoutes.length - 1]) !== activeStepIndex &&
    activeStepIndex !== 0;

  return {
    isQuoteOrPurchaseFlow,
    hasReachedPurchaseFlow,
    steps,
    formRoutes,
    activeStepIndex,
    totalStepCount,
    isInQuoteFlow,
    isQuoteCompleted: isQuotePageCompleted ?? false,
    canNavigateToPreviousStep,
    showPurchaseFlow,
    navigateToPreviousStep,
  };
};

export default useRoutes;
