import { faPaw } from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography } from "@mui/material";
import { currency } from "@racwa/utils";
import { EMPTY_URL, MyRacAccountDisplay } from "raci-react-library";
import { StyledDivider, StyledFadeWiggleIcon, StyledFinePrint, StyledGrid } from "../../styled";

export interface SuccessfulConfirmationProps {
  firstName?: string | null;
  policyNumber: string;
  total?: number;
  receiptNumber?: string | null;
  petName?: string;
}

const SuccessfulConfirmation = ({
  firstName,
  policyNumber,
  total = 0,
  receiptNumber,
  petName,
}: SuccessfulConfirmationProps) => {
  const totalValue = () => (
    <>
      {receiptNumber ? "" : " | "}Total {currency.format(total)}
    </>
  );

  const policyLine = () => (
    <StyledFinePrint data-testid="policyNumberLabel">
      Policy number {policyNumber}
      {receiptNumber ? "" : totalValue()}
    </StyledFinePrint>
  );

  const receiptLine = () =>
    receiptNumber && (
      <StyledFinePrint data-testid="receiptNumberLabel">
        {totalValue()} | Receipt number [{receiptNumber}]
      </StyledFinePrint>
    );

  const confirmationText = `${petName ? petName : "Your pet"} is paw-tected, ${firstName}!`;

  return (
    <StyledGrid container>
      <Grid item xs={12} sx={{ marginBottom: "24px" }}>
        <StyledFadeWiggleIcon icon={faPaw} size="7x" />
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "32px" }}>
        <Typography variant="h2" id="header" data-testid="header" data-hj-suppress>
          {confirmationText}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "32px" }}>
        <Typography variant="body1" id="subHeader" data-testid="subHeader">
          <b>You'll receive an email with your policy documents shortly.</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {policyLine()}
        {receiptLine()}
        <StyledDivider />
      </Grid>
      <MyRacAccountDisplay
        myRacLogInUrl={process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL}
        homePageUrl={process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL}
      />
    </StyledGrid>
  );
};

export default SuccessfulConfirmation;
