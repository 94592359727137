import { Link, Stack, Typography, useMediaQuery } from "@mui/material";
import { theme } from "@racwa/react-components";

export const Disclaimer = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack direction="column" spacing={2} sx={{ paddingTop: "16px" }}>
      <Typography id="terms-1" variant="body2" sx={{ fontWeight: 400 }}>
        Please remember that this is a quote only and does not provide cover. The final information you provide to us
        may affect the terms and premium we offer.
      </Typography>
      <Typography id="terms-2" variant="body2" sx={{ fontWeight: 400 }}>
        It's important that you read and understand the{" "}
        <Link href={process.env.REACT_APP_PDS_PED_URL} rel="noreferrer noopener" target="_blank">
          Product Disclosure Statement (PDS)
        </Link>{" "}
        to ensure the cover meets your needs. Please contact us if you have any difficulty understanding the PDS or your
        quote.
      </Typography>
      <Typography id="terms-3" variant="body2" sx={{ fontWeight: 400, marginBottom: isMobile ? "68px" : "" }}>
        *Discount applies to new policyholders for the first year of insurance only and is subject to a minimum premium.
      </Typography>
    </Stack>
  );
};

export default Disclaimer;
