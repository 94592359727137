import { startOfDay } from "date-fns";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PURCHASE_MORE_ABOUT_YOUR_PET_PAGE_URL } from "../../../../shared/routing/routes.config";
import { StartDateFormProps, StartDateState } from "../../types";

export const useStartDate = (): StartDateFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [sessionState, setSessionState] = useSessionState<StartDateState>();

  const form = useForm<StartDateState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      ...sessionState,
      startDate: sessionState.startDate ? new Date(sessionState.startDate) : undefined,
    },
  });

  const onSubmit = async (newValues: StartDateState) => {
    try {
      setBackdrop(true);
      setSessionState({ ...newValues, isCompleted: true });
      navigate(PURCHASE_MORE_ABOUT_YOUR_PET_PAGE_URL);
    } catch (exception) {
    } finally {
      setBackdrop(false);
    }
  };

  // TODO: Need to do validation for the maximum start date
  // In caravan, this is sent from the BFF

  return {
    form,
    onSubmit,
    defaultStartDate: sessionState.startDate ?? new Date(),
    minimumStartDate: startOfDay(new Date()),
  };
};

export default useStartDate;
