import { useMsal } from "@azure/msal-react";
import { useAccount } from "@racwa/react-adb2c";
import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { NewBusinessPetApiClient } from "raci-new-business-pet-clientproxy";
import { EMPTY_URL, useSessionIdStorageKey } from "raci-react-library";
import {
  DEFAULT_SHIELD_ENVIRONMENT,
  HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION,
  HTTP_HEADER_KEY_SHIELD_ENVIRONMENT,
  useFeatureToggles,
  useShieldEnvironment,
} from "raci-react-library-npe";
import { useMemo } from "react";
import { UseApiClientProps } from "../../shared/hooks/useApiClient";

const useApi = <T>({ Client, apiBaseUrl }: UseApiClientProps<T>) => {
  const { instance } = useMsal();
  const account = useAccount({
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID ?? undefined,
  });
  const { selected: selectedShieldEnv } = useShieldEnvironment();
  const [toggles] = useFeatureToggles();
  const sessionIdStorageKey = useSessionIdStorageKey();

  return useMemo(() => {
    const npeLoginRequest = {
      scopes: [],
      redirectUri: "/",
      account: account,
    };

    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      let token = "";

      try {
        const tokenResponse = await instance.acquireTokenSilent(npeLoginRequest);
        token = tokenResponse.idToken;
      } catch (ex) {
        await instance.acquireTokenRedirect(npeLoginRequest);
      }

      const headers: any = { ...init?.headers };
      const sessionId = sessionStorage.getItem(sessionIdStorageKey);
      if (sessionId) {
        headers.SessionId = sessionId;
      }

      toggles?.forEach((toggle) => {
        if (toggle.enabled !== null) {
          headers[toggle.headerName] = toggle.enabled;
        }
      });

      if (selectedShieldEnv && selectedShieldEnv !== DEFAULT_SHIELD_ENVIRONMENT) {
        headers[HTTP_HEADER_KEY_SHIELD_ENVIRONMENT] = selectedShieldEnv;
      }
      if (token) {
        headers[HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION] = token;
      }
      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new Client(apiBaseUrl, {
      fetch: fetchWrapper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionIdStorageKey]);
};

const useApiClient = () => {
  return useApi({ Client: NewBusinessPetApiClient, apiBaseUrl: process.env.REACT_APP_API_BASE_URL || EMPTY_URL });
};

const useLookupApiClient = () => {
  return useApi({ Client: LookupServiceApiClient, apiBaseUrl: process.env.REACT_APP_LOOKUP_API_BASE_URL || EMPTY_URL });
};

export { useApiClient, useLookupApiClient };
export default useApiClient;
