import { AllReferenceDataTypePetTypes } from "raci-new-business-pet-clientproxy";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import { QUOTE_ABOUT_YOUR_PET_PAGE_URL } from "../../../../shared/routing/routes.config";
import { YourPetFormProps, YourPetState } from "../../types";

export const useYourPet = (): YourPetFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [referenceDataLoaded, setReferenceDataLoaded] = useState(false);
  const [sessionState, setSessionState] = useSessionState<YourPetState>();
  const { catBreeds, dogBreeds } = useReferenceData();

  const form = useForm<YourPetState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  useEffect(() => {
    if (catBreeds.contents.length === 0 || dogBreeds.contents.length === 0) {
      setBackdrop(true);
    } else {
      setReferenceDataLoaded(true);
      setBackdrop(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catBreeds.contents, dogBreeds.contents, referenceDataLoaded]);

  const onSubmit = async (newValues: YourPetState) => {
    if (newValues.petType !== AllReferenceDataTypePetTypes.Other) {
      setSessionState({ ...newValues, isCompleted: true });
      setBackdrop(true);
      navigate(QUOTE_ABOUT_YOUR_PET_PAGE_URL);
      setBackdrop(false);
    }
  };

  return {
    form,
    data: { catBreeds: catBreeds.contents, dogBreeds: dogBreeds.contents },
    onSubmit,
  };
};

export default useYourPet;
