import { Button, Grid } from "@mui/material";
import { QuoteResponsePremiumUpdateReason } from "raci-new-business-pet-clientproxy";
import { PersonalInformation, PremiumChangeDialog } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import useFindAddress from "../../shared/hooks/useFindAddress";
import { YourDetailsFormProps } from "./types";

export const YourDetailsForm = ({
  form,
  onSubmit,
  isMember,
  showPremiumChangeDialog,
  setShowPremiumChangeDialog,
  redirectToNextPage,
  paymentFrequency,
  premiumDetails,
  premiumUpdateReason,
}: YourDetailsFormProps) => {
  const { handleSubmit } = form;

  const { onAddressInputChange, onAddressSelection, addressResults } = useFindAddress();

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1} sx={{ marginTop: "16px" }}>
          <Grid item xs={12}>
            {" "}
            <PersonalInformation
              pageTitle=""
              onAddressSelection={onAddressSelection}
              addressResults={addressResults}
              onAddressInputChange={onAddressInputChange}
              showTitleGender={!isMember}
              showFirstName={!isMember}
              showLastName={!isMember}
              showContactNumber={!isMember}
              showEmail={!isMember}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: "16px" }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        {/* TODO: Need to pass in BodyText to the RRL component, to give a better membership update, to make the copy the same as Caravan */}
        <PremiumChangeDialog
          title={`${
            premiumUpdateReason === QuoteResponsePremiumUpdateReason.Membership
              ? "We've updated your quote with your membership details"
              : "We've updated your quote"
          }`}
          showDialog={showPremiumChangeDialog}
          setShowDialog={() => setShowPremiumChangeDialog(!showPremiumChangeDialog)}
          paymentFrequency={paymentFrequency}
          onButtonClick={redirectToNextPage}
          onClose={redirectToNextPage}
          premiumDetails={premiumDetails}
        />
      </form>
    </FormProvider>
  );
};

export default YourDetailsForm;
