import { useQuery } from "@tanstack/react-query";
import { HTTP_STATUS_CODE_NO_CONTENT, HTTP_STATUS_CODE_OK } from "raci-react-library";
import { EnvironmentHealthStatusButton, SystemHealthInformation, bffVersionAtom } from "raci-react-library-npe";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import useApiClient from "../../shared/hooks/useApiClient";

export const EnvironmentHealthStatus = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [healthStatus, setHealthStatus] = useState<SystemHealthInformation>({});
  const setBffVersion = useSetRecoilState(bffVersionAtom);
  const apiClient = useApiClient();

  const getStatus = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const response = await apiClient.getHealthStatus();
      if (response.status !== HTTP_STATUS_CODE_OK && response.status !== HTTP_STATUS_CODE_NO_CONTENT) {
        setIsError(true);
      } else {
        if (response.status === HTTP_STATUS_CODE_OK) {
          setHealthStatus(response.result);
          if (response.result.version) {
            setBffVersion({
              version: response.result.version.split("+")[0],
              buildId: response.result.version.split("+")[1],
            });
          }
        }
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useQuery({
    queryKey: ["getStatus"],
    queryFn: getStatus,
    staleTime: Infinity,
  });

  return (
    <EnvironmentHealthStatusButton systemHealthInformation={healthStatus} isLoading={isLoading} isError={isError} />
  );
};

export default EnvironmentHealthStatus;
