import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import { PURCHASE_YOUR_DETAILS_PAGE_URL, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { YourPetState } from "../../../YourPet/types";
import { VetAndInsuranceFormProps, VetAndInsuranceState } from "../../types";

export const useVetAndInsurance = (): VetAndInsuranceFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [referenceDataLoaded, setReferenceDataLoaded] = useState(false);
  const [sessionState, setSessionState] = useSessionState<VetAndInsuranceState>();
  const yourPetState = useGetSessionState<YourPetState>(QuoteRoutes.YourPet);
  const { insuranceCompanies } = useReferenceData();

  const form = useForm<VetAndInsuranceState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  useEffect(() => {
    if (insuranceCompanies.length === 0) {
      setBackdrop(true);
    } else {
      setReferenceDataLoaded(true);
      setBackdrop(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceCompanies, referenceDataLoaded]);

  const onSubmit = async (newValues: VetAndInsuranceState) => {
    try {
      setBackdrop(true);
      setSessionState({ ...newValues, isCompleted: true });
      navigate(PURCHASE_YOUR_DETAILS_PAGE_URL);
    } catch (exception) {
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    petName: yourPetState.petName,
    data: { insuranceCompanies: insuranceCompanies ?? [] },
  };
};

export default useVetAndInsurance;
