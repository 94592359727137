import { Suburb } from "raci-new-business-pet-clientproxy";
import { Suburb as RRLSuburb } from "raci-react-library";
import { Dispatch, SetStateAction } from "react";
import { UseFormReturn } from "react-hook-form";

export enum QuoteMatchAttempt {
  NoAttempt = "NoAttempt",
  Matched = "Matched",
  NoMatch = "NoMatch",
  TooManyAttempts = "TooManyAttempts",
}

export interface RetrieveQuoteFormProps {
  form: UseFormReturn<RetrieveQuoteState>;
  onSubmit: (answers: RetrieveQuoteState) => Promise<void>;
  setQuoteNotFound: Dispatch<SetStateAction<boolean>>;
  suburbs: RRLSuburb[];
  quoteMatchAttempt?: QuoteMatchAttempt;
}

export interface RetrieveQuoteState {
  quoteNumber: string;
  suburb: Suburb;
}
