import { ContactMatchResponseMembershipTier } from "raci-new-business-pet-clientproxy";
import { SessionState } from "raci-react-library";
import { UseFormReturn } from "react-hook-form";

export enum YesNoSkip {
  Yes = "Yes",
  No = "No",
  Skip = "Skip",
}

export enum MemberMatchAttempt {
  NoAttempt = "NoAttempt",
  Matched = "Matched",
  NoMatch = "NoMatch",
  Conflict = "Conflict",
}

export interface MemberDetailsFormProps {
  form: UseFormReturn<MemberDetailsState>;
  onSubmit: (answers: MemberDetailsState) => Promise<void>;
  onNoOrSkipMemberMatch: () => void;
  memberMatchAttempt?: MemberMatchAttempt;
  membershipDiscount?: string;
  onMemberSuccessfullyMatched: () => void;
}

export interface MemberDetailsState extends SessionState {
  areYouAnRacMember?: YesNoSkip;
  firstName?: string;
  dateOfBirth?: Date;
  mobileNumber?: string;
  email?: string;
  membershipTier?: ContactMatchResponseMembershipTier;
}
