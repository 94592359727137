import { Button, Grid, Link, Typography } from "@mui/material";
import {
  InsurancePhoneNumberLink,
  NotificationCard,
  QuoteNumberInput,
  QuotePrefix,
  SuburbInput,
} from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { UTILITY_IMPORTANT_INFORMATION_URL } from "../../shared/routing/routes.config";
import { QuoteMatchAttempt, RetrieveQuoteFormProps } from "./types";

export const RetrieveQuoteForm = ({
  form,
  onSubmit,
  setQuoteNotFound,
  suburbs,
  quoteMatchAttempt,
}: RetrieveQuoteFormProps) => {
  const { handleSubmit } = form;

  return (
    <Grid container justifyContent="center" alignItems="center">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} action="#">
          <Grid container spacing={1} sx={{ justifyContent: "center", maxWidth: { sm: "600px" } }}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h2" id="header">
                  Let's retrieve your quote
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <QuoteNumberInput
                  sublabel="You'll find this on your quote summary email."
                  prefix={QuotePrefix.QTE}
                  onChange={() => setQuoteNotFound(false)}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: "16px" }}>
                <SuburbInput
                  id="suburb"
                  sublabel="This is the suburb where your pet lives."
                  options={suburbs ?? []}
                  placeholder="Enter suburb"
                  errorMessage="Please enter a suburb"
                  onChange={() => setQuoteNotFound(false)}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: "8px" }}>
                <Typography variant="body2" id="please-note">
                  Please note that your quote may have changed. If there are any updates, we'll retrieve your latest
                  quote.
                </Typography>
              </Grid>
              {quoteMatchAttempt === QuoteMatchAttempt.NoMatch && (
                <Grid item xs={12} sx={{ marginTop: "16px", marginBottom: "16px" }}>
                  <NotificationCard
                    severity="error"
                    message={{
                      title: "Your quote number and suburb don't match",
                      content: (
                        <Typography variant="body2">
                          Please check your details and try again or{" "}
                          <Link href={UTILITY_IMPORTANT_INFORMATION_URL} id="get-a-new-quote" rel="noreferrer noopener">
                            get a new quote
                          </Link>
                          {"."}
                        </Typography>
                      ),
                    }}
                  />
                </Grid>
              )}
              {quoteMatchAttempt === QuoteMatchAttempt.TooManyAttempts && (
                <Grid item xs={12} sx={{ marginTop: "16px", marginBottom: "16px" }}>
                  <NotificationCard
                    severity="error"
                    message={{
                      title: "Sorry, we've hit a roadblock",
                      content: (
                        <Typography variant="body2">
                          Unfortunately, we can't retrieve your quote. Please call us on{" "}
                          <InsurancePhoneNumberLink id="insurancePhoneNumber" /> with your quote number.
                        </Typography>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  id="btn-retrieve-quote"
                  name="btn-retrieve-quote"
                  type="submit"
                  role="button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: "16px" }}
                >
                  Retrieve quote
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  );
};

export default RetrieveQuoteForm;
