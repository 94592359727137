import { faCheck, faEnvelopeOpenText, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse } from "@mui/material";
import { EmailInput, EmailValidationPattern } from "raci-react-library";
import { useState } from "react";

export interface EmailQuoteProps {
  onEmailQuote: (email: string) => Promise<void>;
}

export const EmailQuote = ({ onEmailQuote }: EmailQuoteProps) => {
  const [open, setOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const iconStyle: React.CSSProperties = {
    position: "relative",
    top: open ? "5px" : "-5px",
  };

  return (
    <>
      <Button
        type="button"
        id="button-toggle-email-quote-form"
        variant="contained"
        color={open ? "inherit" : "secondary"}
        fullWidth
        endIcon={<FontAwesomeIcon icon={open ? faSortUp : faSortDown} style={iconStyle} />}
        sx={{
          marginTop: `15px`,
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        Email quote
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <EmailInput
          id="email"
          defaultValue={""}
          onChange={(e) => {
            setEmailSent(false);
            setEmailAddress(e.target.value);
          }}
        />
        <Button
          type="button"
          id="email-quote"
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => {
            if (new RegExp(EmailValidationPattern).test(emailAddress)) {
              onEmailQuote(emailAddress);
              setEmailSent(true);
              setTimeout(() => setOpen(false), 2000);
            }
          }}
          startIcon={<FontAwesomeIcon icon={emailSent ? faCheck : faEnvelopeOpenText} />}
        >
          {emailSent ? "Quote sent" : "Send quote"}
        </Button>
      </Collapse>
    </>
  );
};

export default EmailQuote;
