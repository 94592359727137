import { Check } from "@mui/icons-material";
import { Card, CardContent, Grid, Link, Typography } from "@mui/material";

interface CardMessageProps {
  message: string;
}
const CheckedMessage: React.FC<CardMessageProps> = ({ message }) => {
  return (
    <>
      <Grid item xs={1}>
        <Check fontSize="small" />
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body2" gutterBottom paragraph>
          {message}
        </Typography>
      </Grid>
    </>
  );
};

export const WhatIsIncluded = () => {
  return (
    <Card>
      <CardContent sx={{ backgroundColor: "white" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom paragraph sx={{ fontWeight: 400, fontSize: "18px" }}>
              What's included
            </Typography>
          </Grid>
          <Grid container>
            <CheckedMessage message="These aren't real inclusions, just zombified from the 'summary' in B2C" />
            <CheckedMessage message="80% of vet costs (less any relevant excess, subject to limits or sub-limits), and the annual limit of $12,000 for ingestion of a foreign object" />
            <CheckedMessage message="Up to $1,000 per year for emergency boarding costs" />
            <CheckedMessage message="New Zealand and Norfolk Island coverage" />
            <CheckedMessage message="And much more!" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom paragraph sx={{ fontWeight: 400, paddingTop: "16px" }}>
            This is a summary. See the{" "}
            <Link href={process.env.REACT_APP_PDS_PED_URL} rel="noreferrer noopener" target="_blank">
              Product Disclosure Statement
            </Link>{" "}
            for more information.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "16px" }}>
          <Typography variant="h2" gutterBottom paragraph sx={{ fontWeight: 400, fontSize: "18px" }}>
            Waiting Period
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom paragraph>
            The waiting period starts on the day of your policy start date
          </Typography>
          <Typography variant="body2" gutterBottom paragraph>
            For an illness, this is 21 days
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WhatIsIncluded;
