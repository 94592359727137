import { Divider, Grid, styled } from "@mui/material";
import { FadeWiggleIcon, FinePrint } from "@racwa/react-components";

export const StyledGrid = styled(Grid)({
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledDivider = styled(Divider)({
  marginTop: "32px",
  marginBottom: "16px",
});

export const StyledFadeWiggleIcon = styled(FadeWiggleIcon)(({ theme }) => ({
  width: "100%",
  height: "101px",
  color: theme.palette.secondary.main,
}));

export const StyledFinePrint = styled(FinePrint)({
  marginBottom: "16px",
});
