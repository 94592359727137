import { PetBreed, PetInsuranceCompany, Suburb } from "raci-new-business-pet-clientproxy";
import { atom } from "recoil";
import { ATOM_SESSION_ID } from "../constants";

export const suburbsAtom = atom<Suburb[]>({
  key: "SuburbList",
  default: [],
});

export const sessionIdAtom = atom<string>({
  key: ATOM_SESSION_ID,
  default: "",
});

export const petBreedsAtom = atom<PetBreed[]>({
  key: "PetBreedList",
  default: [],
});

export const petInsuranceCompaniesAtom = atom<PetInsuranceCompany[]>({
  key: "PetInsuranceCompanyList",
  default: [],
});

export const westpacPublicKeyAtom = atom<string>({
  key: "WestpacPublicKey",
  default: "",
});
