import { subWeeks, subYears } from "date-fns";
import { Suburb as RRLSuburb, YesNo, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import { QUOTE_ABOUT_YOU_PAGE_URL, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { YourPetState } from "../../../YourPet/types";
import { AboutYourPetFormProps, AboutYourPetState } from "../../types";

export const useYourPet = (): AboutYourPetFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [referenceDataLoaded, setReferenceDataLoaded] = useState(false);
  const { suburbs } = useReferenceData();
  const [sessionState, setSessionState] = useSessionState<AboutYourPetState>();
  const yourPetState = useGetSessionState<YourPetState>(QuoteRoutes.YourPet);

  const rrlSuburbs = suburbs?.filter((suburb): suburb is RRLSuburb => !!suburb?.cityName) ?? [];

  const form = useForm<AboutYourPetState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  useEffect(() => {
    if (suburbs.length === 0) {
      setBackdrop(true);
    } else {
      setReferenceDataLoaded(true);
      setBackdrop(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suburbs, referenceDataLoaded]);

  const onSubmit = async (newValues: AboutYourPetState) => {
    if (newValues.preExistingIllnessOrInjuryToggle !== YesNo.Yes) {
      try {
        setBackdrop(true);
        setSessionState({ ...newValues, isCompleted: true });
        navigate(QUOTE_ABOUT_YOU_PAGE_URL);
      } catch (exception) {
      } finally {
        setBackdrop(false);
      }
    }
  };

  const maximumPetAge = subWeeks(new Date(), 8);
  const minimumPetAge = subYears(new Date(), 9);

  return {
    form,
    data: { suburbs: rrlSuburbs },
    onSubmit,
    petName: yourPetState.petName,
    minimumPetAge,
    maximumPetAge,
  };
};

export default useYourPet;
