import SuccessfulConfirmation from "./components/SuccessfulConfirmation";
import UnsuccessfulConfirmation from "./components/UnsuccessfulConfirmation";
import { FormProps } from "./types";

export const Form = ({ isSuccessful, firstName, policyNumber, receiptNumber, total, petName }: FormProps) =>
  isSuccessful ? (
    <SuccessfulConfirmation
      firstName={firstName}
      policyNumber={policyNumber}
      receiptNumber={receiptNumber}
      total={total}
      petName={petName ?? ""}
    />
  ) : (
    <UnsuccessfulConfirmation policyNumber={policyNumber} />
  );

export default Form;
