import { CardContent, Grid, Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import { BasicTooltip, ToggleButtonGroup, ToggleButtonOption, YesNo } from "raci-react-library";

interface AdjustPaymentProps {
  onChangeExcess: (value: number) => Promise<void>;
  onChangeTlcCover: (value: boolean) => Promise<void>;
  defaultExcess: number;
  defaultTlcCover: YesNo;
}
export const AdjustPayment = ({
  onChangeExcess,
  onChangeTlcCover,
  defaultExcess,
  defaultTlcCover,
}: AdjustPaymentProps) => {
  // TODO: Have this returned from the BFF, same as done for Caravan, during reference data call
  const tempExcessAmounts: ToggleButtonOption[] = [
    { text: "$0", value: 0 },
    { text: "$100", value: 100 },
    { text: "$200", value: 200 },
  ];

  return (
    <CardContent sx={{ backgroundColor: colors.racGrayLight }}>
      <Typography variant="h3" sx={{ paddingTop: "8px", paddingBottom: "16px" }}>
        Adjust the amount you pay
      </Typography>

      <Grid item xs={12}>
        <hr />
      </Grid>

      <Grid item xs={12}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={7}>
            <Typography variant="body1">
              <b>Excess</b>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <BasicTooltip
              title="Excess"
              id="excessTooltip"
              message="An excess is an amount which you may have to contribute towards your claim. The acceptance of a claim is subject to the payment of any applicable excesses. If you vary your excess, your insurance premium will change."
              showDialog={false}
            />
          </Grid>
          <Grid item xs={4}>
            <ToggleButtonGroup
              id="excess"
              name="excess"
              options={tempExcessAmounts}
              onChange={(_, value) => onChangeExcess(value as number)}
              defaultValue={defaultExcess}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>

      <Grid item xs={12}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={9}>
            <Typography variant="body1">
              <b>Tender Loving Care (TLC) Cover</b>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <BasicTooltip
              title="Tender Loving Care"
              id="tlcTooltip"
              message="Tender loving care cover provides you with a $100 annual limit to cover certain preventative healthcare treatments like micro-chipping, teeth cleaning and prescription food."
              showDialog={false}
            />
          </Grid>
          <Grid item xs={2}>
            <ToggleButtonGroup
              id="tlcCover"
              name="tlcCover"
              options={YesNo}
              onChange={(_, value) => onChangeTlcCover(value === YesNo.Yes)}
              defaultValue={defaultTlcCover}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default AdjustPayment;
