import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  petBreedsAtom,
  petInsuranceCompaniesAtom,
  sessionIdAtom,
  suburbsAtom,
  westpacPublicKeyAtom,
} from "../../atoms";
import { UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";

export const useLoadReferenceData = (shieldEnvironment: string = "") => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const setSuburbs = useSetRecoilState(suburbsAtom);
  const setBreeds = useSetRecoilState(petBreedsAtom);
  const setInsuranceCompanies = useSetRecoilState(petInsuranceCompaniesAtom);
  const setWestpacPublicKey = useSetRecoilState(westpacPublicKeyAtom);
  const sessionId = useRecoilValue(sessionIdAtom);

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, { replace: true });
  };

  const loadReferenceData = async () => {
    try {
      if (sessionId) {
        const { result } = await apiClient.referenceData();
        setSuburbs(result.suburbs ?? []);
        setBreeds(result.petBreeds ?? []);
        setInsuranceCompanies(result.petInsuranceCompanies ?? []);
        setWestpacPublicKey(result.westpacPublishableApiKey ?? "");
      }
    } catch {
      redirectToSystemUnavailable();
    }
  };

  return useQuery({
    queryKey: ["reference-data", shieldEnvironment, sessionId],
    queryFn: async () => await loadReferenceData(),
    staleTime: Infinity,
  });
};

export default useLoadReferenceData;
