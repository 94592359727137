import { RacwaAutocomplete } from "@racwa/react-components";
import { PetInsuranceCompany } from "raci-new-business-pet-clientproxy";
import { Controller, useFormContext } from "react-hook-form";

export interface CurrentInsurerProps {
  disabled?: boolean;
  id: string;
  options: PetInsuranceCompany[];
}

export const CurrentInsurerInput = ({ disabled, id, options }: CurrentInsurerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={id}
      defaultValue={""}
      rules={{
        required: {
          value: !disabled,
          message: "Please select a valid insurer",
        },
      }}
      render={({ field: { ref, value, onBlur, onChange: onComponentChange }, fieldState: { error } }) => (
        <RacwaAutocomplete
          id={id}
          value={value || null}
          label="Current insurer"
          placeholder={`Start typing your insurer`}
          options={options}
          getOptionLabel={(option) => option.description ?? ""}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          filterSelectedOptions
          fullWidth
          autoComplete
          clearOnEscape
          error={!!error}
          helperText={error?.message}
          onChange={(_, v) => {
            onComponentChange && onComponentChange(v);
          }}
          onBlur={() => {
            onBlur && onBlur();
          }}
          inputRef={ref}
        />
      )}
    />
  );
};

export default CurrentInsurerInput;
