import { Button, Grid } from "@mui/material";
import { KeyboardDateInput } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { StartDateFormProps } from "./types";

export const StartDateForm = ({ form, onSubmit, defaultStartDate, minimumStartDate }: StartDateFormProps) => {
  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1} sx={{ marginTop: "16px" }}>
          <Grid item xs={12}>
            <KeyboardDateInput
              id="startDate"
              name="startDate"
              label={"Choose a start date for your policy"}
              defaultValue={defaultStartDate}
              minDate={minimumStartDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: "16px" }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default StartDateForm;
