import { format, parseISO } from "date-fns";
import {
  AdditionalQuoteInformation,
  AdditionalQuoteInformationPetGender,
  PersonalInformation,
  PersonalInformationGender,
  PersonalInformationTitle,
  QuoteResponsePremiumUpdateReason,
} from "raci-new-business-pet-clientproxy";
import { YesNo, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useApiClient } from "../../../../shared/hooks/useApiClient";
import useQuoteSession from "../../../../shared/hooks/useQuoteSession";
import {
  PURCHASE_PAYMENT_PAGE_URL,
  PurchaseRoutes,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { MemberDetailsState, YesNoSkip } from "../../../MemberDetails/types";
import { MoreAboutYourPetState } from "../../../MoreAboutYourPet/types";
import { StartDateState } from "../../../StartDate/types";
import { VetAndInsuranceState } from "../../../VetAndInsurance/types";
import { YourQuoteState } from "../../../YourQuote/types";
import { YourDetailsFormProps, YourDetailsState } from "../../types";

export const useYourDetails = (): YourDetailsFormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [sessionState, setSessionState] = useSessionState<YourDetailsState>();
  const memberDetailsState = useGetSessionState<MemberDetailsState>(QuoteRoutes.MemberDetails);
  const startDateState = useGetSessionState<StartDateState>(PurchaseRoutes.StartDate);
  const moreAboutYourPetState = useGetSessionState<MoreAboutYourPetState>(PurchaseRoutes.MoreAboutYourPet);
  const vetAndInsuranceState = useGetSessionState<VetAndInsuranceState>(PurchaseRoutes.VetAndInsurance);
  const yourQuoteState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const [quoteState, setQuoteState] = useQuoteSession();
  const [showPremiumChangeDialog, setShowPremiumChangeDialog] = useState(false);
  let premiumUpdateReason = QuoteResponsePremiumUpdateReason.None;

  const form = useForm<YourDetailsState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  const getGender = (gender: string | undefined, title: string | undefined) => {
    if (gender && (title === PersonalInformationTitle.Dr || title === PersonalInformationTitle.Mx)) {
      return gender as PersonalInformationGender;
    } else return undefined;
  };

  const onSubmit = async (newValues: YourDetailsState) => {
    try {
      setBackdrop(true);

      const personalInformation: PersonalInformation = {};
      if (memberDetailsState.areYouAnRacMember !== YesNoSkip.Yes) {
        personalInformation.title = newValues.title as PersonalInformationTitle;
        personalInformation.gender = getGender(newValues.gender, newValues.title);
        personalInformation.firstName = newValues.firstName;
        personalInformation.surname = newValues.lastName;
        personalInformation.contactNumber = newValues.contactNumber;
        personalInformation.email = newValues.email;
      }
      personalInformation.mailingAddress = newValues.mailingAddress?.moniker;

      const additionalQuoteInformation: AdditionalQuoteInformation = {
        // @ts-ignore
        policyStartDate: format(parseISO(startDateState.startDate), "yyyy-MM-dd"),
        petGender: moreAboutYourPetState.petGender as unknown as AdditionalQuoteInformationPetGender,
        isPetSterilised: moreAboutYourPetState.petSterilised === YesNo.Yes,
      };

      if (vetAndInsuranceState.hasVet) {
        additionalQuoteInformation.petVetClinicName = vetAndInsuranceState.vetClinicName;
        additionalQuoteInformation.petVetAddress = vetAndInsuranceState.vetAddress?.moniker;
      }

      if (vetAndInsuranceState.existingInsurance) {
        additionalQuoteInformation.petCurrentInsurer = vetAndInsuranceState.currentInsurer?.externalCode;
      }

      const request = {
        additionalQuoteInformation: additionalQuoteInformation,
        personalInformation: personalInformation,
      };
      // @ts-ignore
      const response = await apiClient.updateQuote(request);
      setQuoteState(response.result);
      setSessionState({ ...newValues, gender: getGender(newValues.gender, newValues.title), isCompleted: true });
      if (response.result.hasPremiumUpdated) {
        setShowPremiumChangeDialog(true);
        premiumUpdateReason = response.result.premiumUpdateReason;
      } else {
        navigate(PURCHASE_PAYMENT_PAGE_URL);
      }
    } catch (exception) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    isMember: memberDetailsState.areYouAnRacMember === YesNoSkip.Yes,
    showPremiumChangeDialog,
    setShowPremiumChangeDialog,
    redirectToNextPage: () => navigate(PURCHASE_PAYMENT_PAGE_URL),
    paymentFrequency: yourQuoteState.paymentFrequency,
    premiumDetails: quoteState.premium,
    premiumUpdateReason: premiumUpdateReason,
  };
};

export default useYourDetails;
