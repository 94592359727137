import { lazy } from "react";
import AboutYou from "../../views/AboutYou";
import AboutYourPet from "../../views/AboutYourPet";
import Confirmation from "../../views/Confirmation";
import ImportantInformation from "../../views/ImportantInformation";
import MemberDetails from "../../views/MemberDetails";
import MoreAboutYourPet from "../../views/MoreAboutYourPet";
import Payment from "../../views/Payment";
import RetrieveQuote from "../../views/RetrieveQuote";
import StartDate from "../../views/StartDate";
import VetAndInsurance from "../../views/VetAndInsurance";
import YourDetails from "../../views/YourDetails";
import YourPet from "../../views/YourPet";
import YourQuote from "../../views/YourQuote";
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));

export const QUOTE_SUB_ROUTE = "quote";
export const PURCHASE_SUB_ROUTE = "purchase";

export enum QuoteRoutes {
  MemberDetails = "member-details",
  YourPet = "your-pet",
  AboutYourPet = "about-your-pet",
  AboutYou = "about-you",
  YourQuote = "your-quote",
}

export enum PurchaseRoutes {
  StartDate = "start-date",
  MoreAboutYourPet = "more-about-your-pet",
  YourDetails = "your-details",
  VetAndInsurance = "vet-and-insurance",
  Payment = "payment",
  Confirmation = "confirmation",
}

export enum UtilityRoutes {
  ImportantInformation = "important-information",
  SessionTimeout = "408",
  SystemUnavailable = "error",
  RetrieveQuote = "retrieve-quote",
}

export enum QuotePageNames {
  MemberDetails = "Member details",
  YourPet = "Your pet",
  AboutYourPet = "About your pet",
  AboutYou = "About you",
  YourQuote = "Your quote",
}

export enum PurchasePageNames {
  StartDate = "Start date",
  MoreAboutYourPet = "More about your pet",
  YourDetails = "Your Details",
  VetAndInsurance = "Vet and existing insurance",
  Payment = "Payment",
  Confirmation = "Confirmation",
}

export enum UtilityPageNames {
  ImportantInformation = "Important information",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
  RetrieveQuote = "Retrieve quote",
}

export enum QuotePageHeadings {
  MemberDetails = "Before we get started",
  YourPet = "Let's start with your pet",
  AboutYourPet = "Tell us more about your pet",
  AboutYou = "Now, a bit about you",
  YourQuote = "Here's your quote",
}

export enum PurchasePageHeadings {
  StartDate = "Great, let's set a start date",
  MoreAboutYourPet = "More about your pet",
  VetAndInsurance = "Just two more things about your pet",
  YourDetails = "Tell us more about you",
  Payment = "Purchase policy",
}

export enum UtilityPageHeadings {
  ImportantInformation = "Important information",
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
  Default = "Uh oh!",
  RetrieveQuote = "Welcome back",
}

const getQuoteRouteUrl = (route: QuoteRoutes) => `${process.env.PUBLIC_URL}/${QUOTE_SUB_ROUTE}/${route}`;
const getPurchaseRouteUrl = (route: PurchaseRoutes) => `${process.env.PUBLIC_URL}/${PURCHASE_SUB_ROUTE}/${route}`;
const getUtilityRouteUrl = (route: UtilityRoutes) => `${process.env.PUBLIC_URL}/${route}`;

export const QUOTE_MEMBER_DETAILS_PAGE_URL = getQuoteRouteUrl(QuoteRoutes.MemberDetails);
export const QUOTE_YOUR_PET_PAGE_URL = getQuoteRouteUrl(QuoteRoutes.YourPet);
export const QUOTE_ABOUT_YOUR_PET_PAGE_URL = getQuoteRouteUrl(QuoteRoutes.AboutYourPet);
export const QUOTE_ABOUT_YOU_PAGE_URL = getQuoteRouteUrl(QuoteRoutes.AboutYou);
export const QUOTE_YOUR_QUOTE_PAGE_URL = getQuoteRouteUrl(QuoteRoutes.YourQuote);

export const PURCHASE_START_DATE_PAGE_URL = getPurchaseRouteUrl(PurchaseRoutes.StartDate);
export const PURCHASE_MORE_ABOUT_YOUR_PET_PAGE_URL = getPurchaseRouteUrl(PurchaseRoutes.MoreAboutYourPet);
export const PURCHASE_YOUR_DETAILS_PAGE_URL = getPurchaseRouteUrl(PurchaseRoutes.YourDetails);
export const PURCHASE_VET_AND_INSURANCE_PAGE_URL = getPurchaseRouteUrl(PurchaseRoutes.VetAndInsurance);
export const PURCHASE_PAYMENT_PAGE_URL = getPurchaseRouteUrl(PurchaseRoutes.Payment);
export const PURCHASE_CONFIRMATION_PAGE_URL = getPurchaseRouteUrl(PurchaseRoutes.Confirmation);

export const UTILITY_IMPORTANT_INFORMATION_URL = getUtilityRouteUrl(UtilityRoutes.ImportantInformation);
export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SessionTimeout);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SystemUnavailable);
export const UTILITY_RETRIEVE_QUOTE_URL = getUtilityRouteUrl(UtilityRoutes.RetrieveQuote);

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const quoteRoutesInfo: Record<QuoteRoutes, RouteInformation> = {
  [QuoteRoutes.MemberDetails]: {
    key: QuoteRoutes.MemberDetails,
    path: QUOTE_MEMBER_DETAILS_PAGE_URL,
    name: QuotePageNames.MemberDetails,
    element: <MemberDetails />,
    heading: QuotePageHeadings.MemberDetails,
  },
  [QuoteRoutes.YourPet]: {
    key: QuoteRoutes.YourPet,
    path: QUOTE_YOUR_PET_PAGE_URL,
    name: QuotePageNames.YourPet,
    element: <YourPet />,
    heading: QuotePageHeadings.YourPet,
  },
  [QuoteRoutes.AboutYourPet]: {
    key: QuoteRoutes.AboutYourPet,
    path: QUOTE_ABOUT_YOUR_PET_PAGE_URL,
    name: QuotePageNames.AboutYourPet,
    element: <AboutYourPet />,
  },
  [QuoteRoutes.AboutYou]: {
    key: QuoteRoutes.AboutYou,
    path: QUOTE_ABOUT_YOU_PAGE_URL,
    name: QuotePageNames.AboutYou,
    element: <AboutYou />,
    heading: QuotePageHeadings.AboutYou,
  },
  [QuoteRoutes.YourQuote]: {
    key: QuoteRoutes.YourQuote,
    path: QUOTE_YOUR_QUOTE_PAGE_URL,
    name: QuotePageNames.YourQuote,
    element: <YourQuote />,
    heading: QuotePageHeadings.YourQuote,
  },
};

export const purchaseRoutesInfo: Record<PurchaseRoutes, RouteInformation> = {
  [PurchaseRoutes.StartDate]: {
    key: PurchaseRoutes.StartDate,
    path: PURCHASE_START_DATE_PAGE_URL,
    name: PurchasePageNames.StartDate,
    element: <StartDate />,
    heading: PurchasePageHeadings.StartDate,
  },
  [PurchaseRoutes.MoreAboutYourPet]: {
    key: PurchaseRoutes.MoreAboutYourPet,
    path: PURCHASE_MORE_ABOUT_YOUR_PET_PAGE_URL,
    name: PurchasePageNames.MoreAboutYourPet,
    element: <MoreAboutYourPet />,
  },
  [PurchaseRoutes.VetAndInsurance]: {
    key: PurchaseRoutes.VetAndInsurance,
    path: PURCHASE_VET_AND_INSURANCE_PAGE_URL,
    name: PurchasePageNames.VetAndInsurance,
    element: <VetAndInsurance />,
  },
  [PurchaseRoutes.YourDetails]: {
    key: PurchaseRoutes.YourDetails,
    path: PURCHASE_YOUR_DETAILS_PAGE_URL,
    name: PurchasePageNames.YourDetails,
    element: <YourDetails />,
    heading: PurchasePageHeadings.YourDetails,
  },
  [PurchaseRoutes.Payment]: {
    key: PurchaseRoutes.Payment,
    path: PURCHASE_PAYMENT_PAGE_URL,
    name: PurchasePageNames.Payment,
    element: <Payment />,
    heading: PurchasePageHeadings.Payment,
  },
  [PurchaseRoutes.Confirmation]: {
    key: PurchaseRoutes.Confirmation,
    path: PURCHASE_CONFIRMATION_PAGE_URL,
    name: PurchasePageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.ImportantInformation,
    path: UTILITY_IMPORTANT_INFORMATION_URL,
    name: UtilityPageNames.ImportantInformation,
    element: <ImportantInformation />,
    heading: UtilityPageHeadings.ImportantInformation,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
  {
    key: UtilityRoutes.RetrieveQuote,
    path: UTILITY_RETRIEVE_QUOTE_URL,
    name: UtilityPageNames.RetrieveQuote,
    element: <RetrieveQuote />,
    heading: UtilityPageHeadings.RetrieveQuote,
  },
];

export const quoteRoutes: RouteInformation[] = Object.values(quoteRoutesInfo);
export const purchaseRoutes: RouteInformation[] = Object.values(purchaseRoutesInfo);
export const quoteToPurchaseRoutes: RouteInformation[] = [...quoteRoutes, ...purchaseRoutes];
export const allRoutes = [...quoteRoutes, ...purchaseRoutes, ...utilityRoutes];

export interface UseRoutesResult {
  currentStep: number;
  totalSteps: number;
  onBack: () => void;
}
