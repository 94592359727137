import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { quoteToPurchaseRoutes } from "../../routing/routes.config";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const sidebarTitle = "Pet Insurance";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const commonPageProps = usePage();
  const { steps, activeStepIndex, canNavigateToPreviousStep, navigateToPreviousStep } = useRoutes();

  const contentTitle = quoteToPurchaseRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname.toLowerCase(),
  )?.heading;

  const sidebarContent = useMemo(
    () => (
      <SidebarContent
        pdsUrl={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
        feedbackUrl={process.env.REACT_APP_FEEDBACK_URL || EMPTY_URL}
      />
    ),
    [],
  );

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => navigateToPreviousStep && navigateToPreviousStep(),
  };

  const onStepClick = (_: string, stepIndex: number) => {
    activeStepIndex !== stepIndex && navigate(steps[stepIndex].path);
  };

  return {
    contentTitle,
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
