import { Button, Grid, Typography } from "@mui/material";
import { QuoteResponsePremiumUpdateReason } from "raci-new-business-pet-clientproxy";
import {
  DateOfBirthInput,
  InsurancePhoneNumberLink,
  NotificationCard,
  PremiumChangeDialog,
  ToggleButtonGroup,
  YesNo,
} from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { AboutYouFormProps } from "./types";

export const AboutYouForm = ({
  form,
  isMember,
  onSubmit,
  showPremiumChangeDialog,
  setShowPremiumChangeDialog,
  premiumUpdateReason,
  redirectToNextPage,
  paymentFrequency,
  premiumDetails,
}: AboutYouFormProps) => {
  const { control, handleSubmit } = form;

  const threeYearPolicyIssueWatch = useWatch({ control, name: "threeYearPolicyIssue" });
  const criminalOffenceWatch = useWatch({ control, name: "criminalOffence" });
  const quoteBlock = threeYearPolicyIssueWatch === YesNo.Yes || criminalOffenceWatch === YesNo.Yes;
  const disabled = quoteBlock;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          {!isMember && (
            <Grid item xs={12} sx={{ marginBottom: "16px" }}>
              <DateOfBirthInput label="What is your date of birth?" id="dateOfBirth" />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h3">
              Have you, or any person intended to be insured by or covered by this policy:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label="In the last 3 years, had an insurer refuse, cancel, or void a policy?"
              id="threeYearPolicyIssue"
              name="threeYearPolicyIssue"
              options={YesNo}
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label="In the last 5 years, been found guilty of any criminal offence?"
              id="criminalOffence"
              name="criminalOffence"
              options={YesNo}
              disabled={threeYearPolicyIssueWatch === YesNo.Yes}
            />
          </Grid>
          {quoteBlock && (
            <NotificationCard
              severity="error"
              message={{
                title: "We need some more information",
                content: (
                  <Typography variant="body2">
                    Please call <InsurancePhoneNumberLink id="insurancePhoneNumber" /> to finish your quote.
                  </Typography>
                ),
              }}
            />
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: "16px" }}
              disabled={disabled}
            >
              View quote
            </Button>
          </Grid>
        </Grid>
        {/* TODO: Need to pass in BodyText to the RRL component, to give a better membership update, to make the copy the same as Caravan */}
        <PremiumChangeDialog
          title={`${
            premiumUpdateReason === QuoteResponsePremiumUpdateReason.Membership
              ? "We've updated your quote with your membership details"
              : "We've updated your quote"
          }`}
          showDialog={showPremiumChangeDialog}
          setShowDialog={() => setShowPremiumChangeDialog(!showPremiumChangeDialog)}
          paymentFrequency={paymentFrequency}
          onButtonClick={redirectToNextPage}
          onClose={redirectToNextPage}
          premiumDetails={premiumDetails}
        />
      </form>
    </FormProvider>
  );
};

export default AboutYouForm;
