import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { AllReferenceDataTypePetTypes } from "raci-new-business-pet-clientproxy";
import {
  InsurancePhoneNumberLink,
  NameInput,
  NotificationCard,
  ToggleButtonGroup,
  YesNo,
  useTooltip,
} from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import PetBreedInput from "./components/PetBreed";
import { YourPetFormProps } from "./types";

export const Form = ({ form, data, onSubmit }: YourPetFormProps) => {
  const { control, handleSubmit, resetField } = form;

  const isPetNotDomesticPurpose = useWatch({ control, name: "petPurpose" }) === YesNo.Yes;
  const isOwnedByBusinessOrTrust = useWatch({ control, name: "ownedByBusinessOrTrust" }) === YesNo.Yes;
  const petTypeWatch = useWatch({ control, name: "petType" });
  const invalidPetType = petTypeWatch === AllReferenceDataTypePetTypes.Other;
  const isDog = petTypeWatch === AllReferenceDataTypePetTypes.Dog;
  const isCat = petTypeWatch === AllReferenceDataTypePetTypes.Cat;
  const breedOptions = isDog ? data?.dogBreeds ?? [] : data?.catBreeds ?? [];
  const disabled = invalidPetType || isPetNotDomesticPurpose || isOwnedByBusinessOrTrust;

  const catOrDogTooltipProps = useTooltip({
    title: "Why are we asking this?",
    message:
      "We need to know what type of pet you have so we can tailor your quote and give you the best possible premium. This information is used in conjunction with other information collected during this quote.",
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label="What is your pet?"
              id="petType"
              name="petType"
              options={AllReferenceDataTypePetTypes}
              tooltipProps={catOrDogTooltipProps}
              onChange={() => {
                resetField("petBreed", { defaultValue: { description: "", externalCode: "", id: "", petTypeId: "" } });
                resetField("petName", { defaultValue: "" });
                resetField("petPurpose");
                resetField("ownedByBusinessOrTrust");
              }}
            />
          </Grid>
          {(isDog || isCat) && (
            <>
              <Grid item xs={12}>
                <PetBreedInput id="petBreed" isDog={isDog} breedOptions={breedOptions} />
              </Grid>
              <Grid item xs={12}>
                <NameInput
                  autoCompleteCategory={""}
                  maxLength={20}
                  inputId="petName"
                  fieldName={isDog ? "dog's name" : "cat's name"}
                  fieldLabel={`What is your ${isDog ? "dog" : "cat"}'s name?`}
                  placeholderText={isDog ? "e.g. Spot" : "e.g. Luna"}
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  label={`Is your ${isDog ? "dog" : "cat"} currently used for any purpose other than domestic?`}
                  sublabel="e.g. breeding, farming, guarding, hunting, law enforcement, or racing."
                  id="petPurpose"
                  name="petPurpose"
                  options={YesNo}
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  label={`Is your ${isDog ? "dog" : "cat"} owned by a business or trust?`}
                  id="ownedByBusinessOrTrust"
                  name="ownedByBusinessOrTrust"
                  options={YesNo}
                />
              </Grid>
            </>
          )}
          {(isPetNotDomesticPurpose || isOwnedByBusinessOrTrust || invalidPetType) && (
            <Grid item xs={12}>
              <NotificationCard
                severity="error"
                message={{
                  title: invalidPetType
                    ? "Sorry, we aren't able to insure your pet"
                    : "Sorry, we only provide domestic pet insurance",
                  content: (
                    <Box>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        You have the right to request the information relied upon in making this decision. You can do so
                        by contacting us on <InsurancePhoneNumberLink id="insurancePhoneNumber" />.
                      </Typography>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        To help you find appropriate insurance cover, the Insurance Council of Australia (ICA) has
                        compiled a list of insurance providers who offer general insurance products.
                      </Typography>
                      <Typography variant="body2">
                        Visit{" "}
                        <Link href="https://findaninsurer.com.au" target="_blank" rel="noreferrer noopener">
                          findaninsurer.com.au
                        </Link>{" "}
                        to find insurance for your pet.
                      </Typography>
                    </Box>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: "16px" }}
              disabled={disabled}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
