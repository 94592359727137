import { EMPTY_URL, useSetBackdrop } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { QUOTE_MEMBER_DETAILS_PAGE_URL } from "../../../../shared/routing/routes.config";
import { ImportantInformationFormProps } from "../../types";

export const useImportantInformation = (): ImportantInformationFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();

  const pdsUrl = process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL;
  const privacyUrl = "https://ractest.com.au/about-rac/site-info/privacy";

  const redirectToNextPage = () => {
    setBackdrop(true);
    setTimeout(() => {
      navigate(QUOTE_MEMBER_DETAILS_PAGE_URL);
      setBackdrop(false);
    }, 1500);
  };

  return {
    pdsUrl,
    privacyUrl,
    redirectToNextPage,
  };
};

export default useImportantInformation;
