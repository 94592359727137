import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { NewBusinessPetApiClient } from "raci-new-business-pet-clientproxy";
import { useSessionIdStorageKey } from "raci-react-library";
import { useMemo } from "react";

export type ClientOptions = { fetch: (url: RequestInfo, init?: RequestInit) => Promise<Response> };
export interface UseApiClientProps<T> {
  Client: { new (baseUrl: string, http: ClientOptions): T };
  apiBaseUrl: string;
}

let useRawBffApiClient: () => NewBusinessPetApiClient;
let useRawLookupApiClient: () => LookupServiceApiClient;

const useApi = <T>({ Client, apiBaseUrl }: UseApiClientProps<T>) => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useMemo(() => {
    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      const headers: any = { ...init?.headers };
      const sessionId = sessionStorage.getItem(sessionIdStorageKey);
      if (sessionId) {
        headers.SessionId = sessionId;
      }
      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new Client(apiBaseUrl, {
      fetch: fetchWrapper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionIdStorageKey]);
};

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT"
) {
  const { useApiClient, useLookupApiClient } = require("../../../__NPE__/useNpeApiClient");
  useRawBffApiClient = useApiClient;
  useRawLookupApiClient = useLookupApiClient;
} else {
  useRawBffApiClient = () => {
    return useApi({ Client: NewBusinessPetApiClient, apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "" });
  };
  useRawLookupApiClient = () => {
    return useApi({ Client: LookupServiceApiClient, apiBaseUrl: process.env.REACT_APP_LOOKUP_API_BASE_URL || "" });
  };
}

const useApiClient = () => useRawBffApiClient();
const useLookupApiClient = () => useRawLookupApiClient();

export { useApiClient, useLookupApiClient };
export default useApiClient;
