import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PURCHASE_VET_AND_INSURANCE_PAGE_URL, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { YourPetState } from "../../../YourPet/types";
import { MoreAboutYourPetFormProps, MoreAboutYourPetState } from "../../types";

export const useMoreAboutYourPet = (): MoreAboutYourPetFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [sessionState, setSessionState] = useSessionState<MoreAboutYourPetState>();
  const yourPetState = useGetSessionState<YourPetState>(QuoteRoutes.YourPet);

  const form = useForm<MoreAboutYourPetState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  const onSubmit = async (newValues: MoreAboutYourPetState) => {
    try {
      setBackdrop(true);
      setSessionState({ ...newValues, isCompleted: true });
      navigate(PURCHASE_VET_AND_INSURANCE_PAGE_URL);
    } catch (exception) {
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    petName: yourPetState.petName,
  };
};

export default useMoreAboutYourPet;
