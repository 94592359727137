import { Button, Grid, useMediaQuery } from "@mui/material";
import { theme } from "@racwa/react-components";
import {
  InformationSummary,
  PaymentFrequency,
  PaymentMethod,
  Payment as RRLPayment,
  useBsbLookup,
  useGetSessionState,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLookupApiClient } from "../../shared/hooks/useApiClient";
import {
  PURCHASE_CONFIRMATION_PAGE_URL,
  PurchaseRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../shared/routing/routes.config";
import { PaymentData, PaymentFormProps, PaymentState } from "./types";

export const PaymentForm = ({
  form,
  onSubmit,
  informationSummary,
  premiumDetails,
  defaultPaymentFrequency,
  data: { hasError },
}: PaymentFormProps) => {
  const { handleSubmit } = form;
  const lookupApiClient = useLookupApiClient();
  const navigate = useNavigate();
  const { onBsbLookup } = useBsbLookup({ apiClient: lookupApiClient });
  const [paymentFrequency, setPaymentFrequency] = useState<PaymentFrequency>(defaultPaymentFrequency);
  const { method: paymentMethod } = useGetSessionState<PaymentState>(PurchaseRoutes.Payment);
  const [method] = useState<PaymentMethod | null>(paymentMethod);
  const paymentData = useGetSessionState<PaymentData>(PurchaseRoutes.Payment);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (hasError) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } else if (paymentData.isCompleted) {
      navigate(PURCHASE_CONFIRMATION_PAGE_URL);
    }
  }, [hasError, navigate, paymentData.isCompleted]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RRLPayment
              id="payment"
              isMobile={isMobile}
              policySummary={<InformationSummary {...informationSummary} />}
              onBsbLookup={onBsbLookup}
              saveBranchName={false}
              frequencyProps={{
                isAnnualFrequency: paymentFrequency === PaymentFrequency.Annual,
                setFrequency: setPaymentFrequency,
                annual: premiumDetails.annual,
                monthly: premiumDetails.monthly,
              }}
              methodProps={{
                defaultValue: method ?? paymentData.annualCCPaymentInProgress ? PaymentMethod.Card : undefined,
              }}
              authorisationProps={{
                pdsUrl: process.env.REACT_APP_PDS_PED_URL,
                ddrsaUrl: process.env.REACT_APP_PDS_PED_URL,
                paymentFrequency: paymentFrequency,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained" fullWidth sx={{ marginTop: "16px" }}>
              Purchase Policy
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default PaymentForm;
