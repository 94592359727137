import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaThemeProvider } from "@racwa/react-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  Backdrop,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  TrackPageChanges,
  WebChat,
} from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import PreloadReferenceData from "./shared/components/PreloadReferenceData";
import { SESSION_KEY_PREFIX } from "./shared/constants";
import useCreateSession from "./shared/hooks/useCreateSession";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import useSessionExpiry from "./shared/hooks/useSessionExpiry";
import { applicationInsightsPlugin } from "./shared/utils/applicationInsights";
import Views from "./views";

const queryClient = new QueryClient();

export const App = () => (
  <RacwaThemeProvider>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <AppInsightsContext.Provider value={applicationInsightsPlugin}>
          <Backdrop />
          <BrowserRouter>
            <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
              <SessionStatusProvider useSessionExpiry={useSessionExpiry} useCreateSession={useCreateSession}>
                <PreloadReferenceData />
                <Views />
                <WebChat useGetWebChatInformation={useGetWebChatInformation} />
              </SessionStatusProvider>
            </SessionStatePrefixProvider>
            <TrackPageChanges />
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </RecoilRoot>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </RacwaThemeProvider>
);

export default App;
