import { Button, Card, CardContent, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "@racwa/react-components";
import { QuotePremium } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import AdjustPayment from "./components/AdjustPayment";
import Disclaimer from "./components/Disclaimer";
import EmailQuote from "./components/EmailQuote";
import WhatIsIncluded from "./components/WhatIsIncluded";
import { YourQuoteFormProps } from "./types";

export const YourQuoteForm = ({
  form,
  quoteNumber,
  premiumDetails,
  discounts,
  defaultPaymentFrequency,
  onSubmit,
  onEmailQuote,
  onChangeExcess,
  onChangeTlcCover,
  defaultExcess,
  defaultTlcCover,
}: YourQuoteFormProps) => {
  const { handleSubmit } = form;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ paddingBottom: "8px" }}>
              <span style={{ fontWeight: 400 }}>Your quote number is</span>{" "}
              <span style={{ fontWeight: 700 }}>{quoteNumber}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined">
              <QuotePremium
                id="payment-frequency"
                name="paymentFrequency"
                premiumDetails={premiumDetails}
                defaultFrequency={defaultPaymentFrequency}
                isMobile={isMobile}
                quoteHeading={
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={10}>
                      <Typography variant="h3">Your pet insurance</Typography>
                    </Grid>
                  </Grid>
                }
                discounts={discounts}
              />
              <Divider />
              <AdjustPayment
                onChangeExcess={onChangeExcess}
                defaultExcess={defaultExcess}
                onChangeTlcCover={onChangeTlcCover}
                defaultTlcCover={defaultTlcCover}
              />
              <Divider />
              <CardContent>
                <Button type="submit" color="primary" variant="contained" fullWidth>
                  Get policy
                </Button>
                <EmailQuote onEmailQuote={onEmailQuote} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WhatIsIncluded />
        </Grid>
        <Grid item xs={12}>
          <Disclaimer />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default YourQuoteForm;
