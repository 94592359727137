import { Box, Grid, Typography } from "@mui/material";
import {
  FindMemberDetailsButton,
  InsurancePhoneNumberLink,
  NotificationCard,
  ToggleButtonGroup,
  useTooltip,
} from "raci-react-library";
import { useEffect } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import MemberMatch from "./components/MemberMatch";
import SkipLink from "./components/SkipLink";
import { MemberDetailsFormProps, MemberMatchAttempt, YesNoSkip } from "./types";

export const MemberDetailsForm = ({
  form,
  onSubmit,
  onNoOrSkipMemberMatch,
  memberMatchAttempt,
  membershipDiscount,
  onMemberSuccessfullyMatched,
}: MemberDetailsFormProps) => {
  const { control, handleSubmit } = form;
  const areYouAnRacMemberWatch = useWatch({ control, name: "areYouAnRacMember" });
  const firstNameWatch = useWatch({ control: form.control, name: "firstName" });
  const isMember = areYouAnRacMemberWatch === YesNoSkip.Yes;
  const IsNotMember = areYouAnRacMemberWatch === YesNoSkip.No || areYouAnRacMemberWatch === YesNoSkip.Skip;

  const racMemberToolTipProps = useTooltip({
    title: "Are you an RAC member?",
    message:
      "If you've any of these RAC products you're a member: Roadside Assistance, Insurance, FREE2go, Life Insurance, Health Insurance, Pet Insurance, Finance, Security Monitoring or Rewards Membership.",
  });

  useEffect(() => {
    if (IsNotMember) {
      onNoOrSkipMemberMatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsNotMember]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label="Are you an RAC member?"
              sublabel="We'll use this to pre-fill your details and apply eligible discounts."
              id="areYouAnRacMember"
              name="areYouAnRacMember"
              options={YesNoSkip}
              tooltipProps={racMemberToolTipProps}
            />
          </Grid>
          {isMember && (
            <>
              <Grid item xs={12}>
                <MemberMatch />
              </Grid>
              {memberMatchAttempt === MemberMatchAttempt.NoMatch && (
                <Grid item xs={12}>
                  <NotificationCard
                    severity="warning"
                    message={{
                      title: "We couldn't find your details",
                      paragraphs: [
                        "Please check that you've entered your personal information correctly.",
                        "Alternatively, if you're known by different names try your legal or preferred name.",
                        "If we still can't find your details select skip to continue and we'll try again later.",
                      ],
                    }}
                  />
                </Grid>
              )}
              {memberMatchAttempt === MemberMatchAttempt.Conflict && (
                <Grid item xs={12}>
                  <NotificationCard
                    severity="warning"
                    message={{
                      title: "We need some more information to identify you",
                      content: (
                        <Typography variant="body2">
                          Please call <InsurancePhoneNumberLink id="insurancePhoneNumber" /> if you think there has been
                          a mistake with your details.
                        </Typography>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sx={{ marginTop: "16px" }}>
                {/* TODO: Handle "Disabled" states, so you can't double request a membership */}
                <FindMemberDetailsButton
                  firstName={firstNameWatch}
                  hasMatch={memberMatchAttempt === MemberMatchAttempt.Matched}
                  memberDiscount={membershipDiscount}
                  afterAnimationRedirect={onMemberSuccessfullyMatched}
                />
                {/* TODO: Make sure this Box/Skip fits the same as caravan for where it is placed, using "states" */}
                <Box pt={3} pb={3} width={1}>
                  <SkipLink />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default MemberDetailsForm;
