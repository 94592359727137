import { RacwaAutocomplete } from "@racwa/react-components";
import { PetBreed } from "raci-new-business-pet-clientproxy";
import { useTooltip } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";

export interface PetBreedProps {
  breedOptions: PetBreed[];
  isDog: boolean;
  disabled?: boolean;
  id: string;
}

export const PetBreedInput = ({ breedOptions, isDog, disabled, id }: PetBreedProps) => {
  const { control } = useFormContext();

  const petBreedTooltipProps = useTooltip({
    title: "Why do you need my pet's breed?",
    message:
      "Your pet's breed is required so we can tailor your quote and give you the best possible premium. This information is used in conjunction with other information collected during this quote. Certain breeds are prohibited by the Western Australia government and may not appear on this list.",
  });

  return (
    <Controller
      control={control}
      name={id}
      defaultValue={""}
      rules={{
        required: {
          value: !disabled,
          message: `Please select a valid ${isDog ? "dog" : "cat"} breed`,
        },
      }}
      render={({ field: { ref, value, onBlur, onChange: onComponentChange }, fieldState: { error } }) => (
        <RacwaAutocomplete
          id={id}
          value={value || null}
          label={`What breed is your ${isDog ? "dog" : "cat"}?`}
          sublabel={`If your ${isDog ? "dog" : "cat"} is a mixed breed, select the dominant breed.`}
          placeholder={`Start typing your ${isDog ? "dog" : "cat"}'s breed`}
          options={breedOptions}
          getOptionLabel={(option) => option.description ?? ""}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          filterSelectedOptions
          tooltipProps={petBreedTooltipProps}
          fullWidth
          autoComplete
          clearOnEscape
          error={!!error}
          helperText={error?.message}
          onChange={(_, v) => {
            onComponentChange && onComponentChange(v);
          }}
          onBlur={() => {
            onBlur && onBlur();
          }}
          inputRef={ref}
        />
      )}
    />
  );
};

export default PetBreedInput;
