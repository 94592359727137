import {
  IssuePolicyRequest,
  IssuePolicyRequestFrequency,
  NewBusinessPetApiException,
} from "raci-new-business-pet-clientproxy";
import {
  InformationSummaryProps,
  InformationSummarySubsection,
  PaymentFrequency,
  PaymentMethod,
  YesNo,
  useGetSessionState,
  useSessionState,
  useSetBackdrop,
  useWestpacScript,
} from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import useQuoteSession from "../../../../shared/hooks/useQuoteSession";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import {
  PURCHASE_CONFIRMATION_PAGE_URL,
  PURCHASE_MORE_ABOUT_YOUR_PET_PAGE_URL,
  PURCHASE_START_DATE_PAGE_URL,
  PURCHASE_VET_AND_INSURANCE_PAGE_URL,
  PURCHASE_YOUR_DETAILS_PAGE_URL,
  PurchasePageNames,
  PurchaseRoutes,
  QUOTE_ABOUT_YOUR_PET_PAGE_URL,
  QUOTE_YOUR_PET_PAGE_URL,
  QUOTE_YOUR_QUOTE_PAGE_URL,
  QuotePageNames,
  QuoteRoutes,
} from "../../../../shared/routing/routes.config";
import { AboutYouState } from "../../../AboutYou/types";
import { AboutYourPetState } from "../../../AboutYourPet/types";
import { MemberDetailsState, YesNoSkip } from "../../../MemberDetails/types";
import { StartDateState } from "../../../StartDate/types";
import { VetAndInsuranceState } from "../../../VetAndInsurance/types";
import { YourDetailsState } from "../../../YourDetails/types";
import { YourPetState } from "../../../YourPet/types";
import { YourQuoteState } from "../../../YourQuote/types";
import { PaymentData, PaymentFormProps, PaymentState } from "../../types";

interface OnSubmitBankAccountEvent extends IssuePolicyRequest {}

export const usePayment = (): PaymentFormProps => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const apiClient = useApiClient();
  const { westpacPublicKey } = useReferenceData();
  const [sessionState] = useSessionState<PaymentState>();
  const yourPetState = useGetSessionState<YourPetState>(QuoteRoutes.YourPet);
  const memberDetailsState = useGetSessionState<MemberDetailsState>(QuoteRoutes.MemberDetails);
  const aboutYourPetState = useGetSessionState<AboutYourPetState>(QuoteRoutes.AboutYourPet);
  const aboutYouState = useGetSessionState<AboutYouState>(QuoteRoutes.AboutYou);
  const yourQuoteState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const startDateState = useGetSessionState<StartDateState>(PurchaseRoutes.StartDate);
  const yourDetailsState = useGetSessionState<YourDetailsState>(PurchaseRoutes.YourDetails);
  const vetAndInsuranceState = useGetSessionState<VetAndInsuranceState>(PurchaseRoutes.VetAndInsurance);
  const [quoteState] = useQuoteSession();
  const [isError, setIsError] = useState(false);
  const [, setPaymentData] = useSessionState<PaymentData>({
    specificKey: PurchaseRoutes.Payment,
    skipPageTrackingRecalculation: true,
  });

  const petName = yourPetState.petName ? yourPetState.petName : "Your pet";

  const form = useForm<PaymentState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  const { watch } = form;
  const method = watch("method") as PaymentMethod;
  const frequency = watch("frequency") as PaymentFrequency;
  const isRealtimePayment = method === PaymentMethod.Card && frequency === PaymentFrequency.Annual;
  const isAnnualPaymentFrequency = frequency === PaymentFrequency.Annual;

  const { onSubmitWestpac, trustedFrame } = useWestpacScript(
    method === PaymentMethod.Card,
    isRealtimePayment,
    westpacPublicKey,
  );

  const onSubmitCreditCard = async (newValues: PaymentState) => {
    if (trustedFrame && trustedFrame.current) {
      onSubmitWestpac(async (singleUserTokenId: string) => {
        try {
          setBackdrop(true);

          const { result } = await apiClient.issuePolicy({
            frequency: isAnnualPaymentFrequency
              ? IssuePolicyRequestFrequency.Annual
              : IssuePolicyRequestFrequency.Monthly,
            token: singleUserTokenId,
          });

          if (result && result.policyNumber) {
            setPaymentData({
              policyNumber: result.policyNumber,
              receiptNumber: result.receiptNumber,
              firstName: memberDetailsState.firstName,
              isSuccessful: true,
              isCompleted: true,
              lockdownPreviousPage: true,
              hasError: false,
              total: result.total,
            });
            navigate(PURCHASE_CONFIRMATION_PAGE_URL);
          }
        } catch (exception) {
          const errorResponse = exception as NewBusinessPetApiException;
          if (errorResponse.status >= 500) {
            setIsError(true);
          }
        } finally {
          setBackdrop(false);
        }
      });
    }
  };

  const removeTrailingApostrophe = (accountName: string): string => {
    while (accountName.substr(accountName.length - 1) === "'") {
      accountName = accountName.substring(0, accountName.length - 1);
    }
    return accountName;
  };

  const sanitizeAccountName = (accountName: string | undefined): string => {
    if (accountName) {
      accountName = accountName.replace(/—/g, "-");
      accountName = accountName.replace(/’/g, "'");
      accountName = removeTrailingApostrophe(accountName);
      return accountName.trim();
    }
    return "";
  };

  const payWithBankAccount = async (paymentRequest: IssuePolicyRequest) => {
    setBackdrop(true);
    setIsError(false);

    try {
      paymentRequest.accountName = sanitizeAccountName(paymentRequest.accountName);

      const { result } = await apiClient.issuePolicy(paymentRequest);

      if (result && result.policyNumber) {
        setPaymentData({
          policyNumber: result.policyNumber,
          receiptNumber: result.receiptNumber,
          firstName: memberDetailsState.firstName,
          isSuccessful: true,
          isCompleted: true,
          lockdownPreviousPage: true,
          hasError: false,
          total: result.total,
        });
        navigate(PURCHASE_CONFIRMATION_PAGE_URL);
      } else {
        setIsError(true);
      }
    } catch (exception) {
      const errorResponse = exception as NewBusinessPetApiException;
      if (errorResponse.status >= 500) {
        setIsError(true);
      }
    } finally {
      setBackdrop(false);
    }
  };

  const onSubmitBankAccount = async (event: OnSubmitBankAccountEvent): Promise<void> => {
    await payWithBankAccount({
      bsb: event.bsb?.trim(),
      accountNumber: event.accountNumber?.trim(),
      accountName: event.accountName?.trim(),
      frequency: event.frequency,
      method: method,
    } as IssuePolicyRequest);
  };

  const onSubmit = async (newValues: PaymentState) => {
    if (method === PaymentMethod.BankAccount) {
      const bankFrom = form.getValues();
      if (bankFrom.bsb && bankFrom.accountNumber && bankFrom.accountName) {
        await onSubmitBankAccount({
          bsb: newValues.bsb,
          accountName: newValues.accountName,
          accountNumber: newValues.accountNumber,
          frequency: frequency as unknown as IssuePolicyRequestFrequency,
          method: method ?? PaymentMethod.BankAccount,
        } as OnSubmitBankAccountEvent);
      }
    } else if (method === PaymentMethod.Card) {
      console.log("submit credit card", newValues);
      await onSubmitCreditCard(newValues);
    }
  };

  const aboutYouSubsection: InformationSummarySubsection[] = [];
  if (aboutYouState.dateOfBirth) {
    aboutYouSubsection.push({
      id: "details",
      content: [`Date of birth ${new Date(aboutYouState.dateOfBirth).toLocaleDateString()}`],
      onEditClicked: () => navigate(PURCHASE_MORE_ABOUT_YOUR_PET_PAGE_URL),
    });
  }

  if (memberDetailsState.areYouAnRacMember !== YesNoSkip.Yes) {
    aboutYouSubsection.push({
      id: "details",
      content: [
        `${yourDetailsState.firstName} ${yourDetailsState.lastName}`,
        yourDetailsState.contactNumber ?? "",
        yourDetailsState.email ?? "",
        yourDetailsState.mailingAddress?.displayAddress ?? "",
      ],
      onEditClicked: () => navigate(PURCHASE_YOUR_DETAILS_PAGE_URL),
    });
  } else {
    aboutYouSubsection.push({
      id: "details",
      content: [yourDetailsState.mailingAddress?.displayAddress ?? ""],
      onEditClicked: () => navigate(PURCHASE_YOUR_DETAILS_PAGE_URL),
    });
  }

  const vetAndInsuranceSection: InformationSummarySubsection[] = [];
  if (vetAndInsuranceState.hasVet === YesNo.Yes) {
    vetAndInsuranceSection.push({
      id: "details",
      content: [
        `${petName} has a regular vet`,
        vetAndInsuranceState.vetClinicName ?? "",
        vetAndInsuranceState.vetAddress?.displayAddress ?? "",
      ],
      onEditClicked: () => navigate(PURCHASE_VET_AND_INSURANCE_PAGE_URL),
    });
  } else {
    vetAndInsuranceSection.push({
      id: "details",
      content: [`${petName} does not have a regular vet`],
      onEditClicked: () => navigate(PURCHASE_VET_AND_INSURANCE_PAGE_URL),
    });
  }
  vetAndInsuranceSection.push({
    id: "insurance",
    content:
      vetAndInsuranceState.existingInsurance === YesNo.Yes
        ? [`${vetAndInsuranceState.existingInsurance} if your existing insurer`]
        : ["No existing insurance"],
    onEditClicked: () => navigate(PURCHASE_VET_AND_INSURANCE_PAGE_URL),
  });

  const informationSummary: InformationSummaryProps = {
    id: "policy-summary",
    title: "My policy summary",
    subtitle: "Please ensure all details are correct and that the policy suits your needs.",
    expandText: "Show your policy information",
    collapseText: "Hide your policy information",
    visibleSections: [
      {
        id: "your-pet",
        title: QuotePageNames.YourPet,
        subsections: [
          {
            id: "pet-type-breed-name",
            content: [
              `${petName} the ${yourPetState.petBreed.description}`,
              "Only used for domestic purposes",
              "Not owned by a business or trust",
            ],
            onEditClicked: () => navigate(QUOTE_YOUR_PET_PAGE_URL),
          },
        ],
      },
    ],
    hiddenSections: [
      {
        id: "cover",
        title: `${petName}'s cover`,
        subsections: [
          {
            id: "details",
            content: [`Basic excess $${yourQuoteState.excess}`, yourQuoteState.tlcCover ? "TLC cover" : "No TLC cover"],
            onEditClicked: () => navigate(QUOTE_YOUR_QUOTE_PAGE_URL),
          },
        ],
      },
      {
        id: "pet-about",
        title: `${petName}'s details`,
        subsections: [
          {
            id: "about-pet",
            content: [
              "Not pre-existing illness or injuries",
              `Date of birth ${new Date(aboutYourPetState.petDateOfBirth).toLocaleDateString()}`,
              `Lives in ${aboutYourPetState.suburb.cityName}`,
            ],
            onEditClicked: () => navigate(QUOTE_ABOUT_YOUR_PET_PAGE_URL),
          },
          {
            id: "more-about-pet",
            content: [`${petName} is male`, `${petName} has not been sterilised`],
            onEditClicked: () => navigate(PURCHASE_MORE_ABOUT_YOUR_PET_PAGE_URL),
          },
        ],
      },
      {
        id: "your-details",
        title: "Your details",
        subsections: aboutYouSubsection,
      },
      {
        id: "start-date",
        title: PurchasePageNames.StartDate,
        subsections: [
          {
            id: "details",
            content: [`Policy start date ${new Date(startDateState.startDate).toLocaleDateString()}`],
            onEditClicked: () => navigate(PURCHASE_START_DATE_PAGE_URL),
          },
        ],
      },
      {
        id: "vet-and-insurance",
        title: PurchasePageNames.VetAndInsurance,
        subsections: vetAndInsuranceSection,
      },
    ],
  };

  return {
    form,
    onSubmit,
    informationSummary: informationSummary,
    premiumDetails: quoteState.premium,
    defaultPaymentFrequency: yourQuoteState.paymentFrequency,
    data: { hasError: isError },
  };
};

export default usePayment;
