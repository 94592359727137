import { Button, Grid } from "@mui/material";
import { RacwaStepperPageTitle } from "@racwa/react-components";
import { ToggleButtonGroup, YesNo } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { PurchasePageHeadings } from "../../shared/routing/routes.config";
import { MoreAboutYourPetFormProps, PetGender } from "./types";

export const MoreAboutYourPetForm = ({ form, onSubmit, petName }: MoreAboutYourPetFormProps) => {
  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RacwaStepperPageTitle title={petName ? "More about " + petName : PurchasePageHeadings.MoreAboutYourPet} />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label={`What is ${petName ? petName : "your pet"}'s gender?`}
              id="petGender"
              name="petGender"
              options={PetGender}
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label={`Has ${petName ? petName : "your pet"} been sterilised?`}
              id="petSterilised"
              name="petSterilised"
              options={YesNo}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained" fullWidth sx={{ marginTop: "16px" }}>
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default MoreAboutYourPetForm;
