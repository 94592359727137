import { useHasSession } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { utilityRoutes } from "../../routing/routes.config";
import useApiClient from "../useApiClient";

export const useGetWebChatInformation = () => {
  const client = useApiClient();
  const location = useLocation();
  // Note: If using a logged in flow, un-comment the 'SSO' sections, otherwise delete (this is to stop unnecessary calls)
  // See Motor Glass Claims or Claims Servicing for examples
  // SSO: const isAuthenticated = useIsLoggedInToMyRac();
  const hasSession = useHasSession();
  const [getPointOfContactId, setPointOfContactId] = useState("");
  const [getShouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [getCrmId, setCrmId] = useState("");
  // Note: DisallowPages should be error pages, or ones you don't expect the user to use web chat on
  const disallowPages = utilityRoutes.map((route) => route.path.toLowerCase());
  const currentLocation = location.pathname.toLowerCase();

  // SSO: const shouldGetWebChatInformation = isAuthenticated && hasSession && !disallowPages?.includes(currentLocation);
  const shouldGetWebChatInformation = hasSession && !disallowPages?.includes(currentLocation);

  useEffect(() => {
    let isCancelled = false;

    const getWebChatId = async () => {
      try {
        if (!isCancelled) {
          if (shouldGetWebChatInformation) {
            const response = await client.getWebChatContactId();
            setPointOfContactId(response.result.pointOfContactId ?? "");
            setCrmId(response.result.crmId ?? "");
            setShouldRenderWebChat(true);
          } else {
            setShouldRenderWebChat(false);
          }
        }
      } catch {
        setPointOfContactId("");
        setShouldRenderWebChat(false);
      }
    };

    getWebChatId();

    return () => {
      isCancelled = true;
    };
  }, [client, currentLocation, shouldGetWebChatInformation]);

  return { pointOfContactId: getPointOfContactId, crmId: getCrmId, shouldRender: getShouldRenderWebChat };
};

export default useGetWebChatInformation;
