import format from "date-fns/format";
import { ContactMatchResponseMembershipTier, NewBusinessPetApiException } from "raci-new-business-pet-clientproxy";
import {
  HTTP_STATUS_CODE_MULTI_MATCH,
  HTTP_STATUS_CODE_NOT_FOUND,
  HTTP_STATUS_CODE_OK,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { QUOTE_YOUR_PET_PAGE_URL, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../../../shared/routing/routes.config";
import { MemberDetailsFormProps, MemberDetailsState, MemberMatchAttempt, YesNoSkip } from "../../types";

export const useMemberDetails = (): MemberDetailsFormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [sessionState, setSessionState] = useSessionState<MemberDetailsState>();
  const [membershipDiscount, setMembershipDiscount] = useState("");
  const [memberMatchAttempt, setMemberMatchAttempt] = useState(MemberMatchAttempt.NoAttempt);

  const form = useForm<MemberDetailsState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: { ...sessionState, dateOfBirth: sessionState.dateOfBirth && new Date(sessionState.dateOfBirth) },
  });

  // TODO: Return this from the BFF in the member match request (Discount + Name)
  const getMembershipDiscountPercentage = (membershipTier: ContactMatchResponseMembershipTier): string => {
    if (membershipTier === ContactMatchResponseMembershipTier._4) {
      return "10%";
    } else if (membershipTier === ContactMatchResponseMembershipTier._3) {
      return "7.5%";
    } else if (membershipTier === ContactMatchResponseMembershipTier._2) {
      return "5%";
    } else {
      return "";
    }
  };

  const onSubmit = async (values: MemberDetailsState) => {
    try {
      if (values.areYouAnRacMember === YesNoSkip.Yes) {
        setBackdrop(true);
        const response = await apiClient.contactMatch({
          firstName: values.firstName,
          // Requires to be ignored as Typescript requires Date type but serialises date in the wrong format
          // @ts-ignore
          dateOfBirth: (values.dateOfBirth && format(values.dateOfBirth, "yyyy-MM-dd")) || null,
          mobileNumber: values.mobileNumber,
          email: values.email,
        });
        if (response.status === HTTP_STATUS_CODE_OK) {
          setSessionState({
            ...values,
            membershipTier: response.result.membershipTier ?? ContactMatchResponseMembershipTier._0,
            isCompleted: true,
          });
          setMemberMatchAttempt(MemberMatchAttempt.Matched);
          setMembershipDiscount(
            getMembershipDiscountPercentage(response.result.membershipTier ?? ContactMatchResponseMembershipTier._0),
          );
        } else {
          // TODO: Handle sync error
        }
      }
    } catch (ex: unknown) {
      const exception = ex as NewBusinessPetApiException;
      if (exception.status === HTTP_STATUS_CODE_NOT_FOUND) {
        setMemberMatchAttempt(MemberMatchAttempt.NoMatch);
      } else if (exception.status === HTTP_STATUS_CODE_MULTI_MATCH) {
        setMemberMatchAttempt(MemberMatchAttempt.Conflict);
      } else {
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
      }
    } finally {
      setBackdrop(false);
    }
  };

  const onNoOrSkipMemberMatch = () => {
    setSessionState({
      isCompleted: true,
    });
    navigate(QUOTE_YOUR_PET_PAGE_URL);
  };

  const onMemberSuccessfullyMatched = () => {
    navigate(QUOTE_YOUR_PET_PAGE_URL);
  };

  return {
    form,
    onSubmit,
    onNoOrSkipMemberMatch,
    memberMatchAttempt,
    membershipDiscount,
    onMemberSuccessfullyMatched,
  };
};

export default useMemberDetails;
