import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { petBreedsAtom, petInsuranceCompaniesAtom, suburbsAtom, westpacPublicKeyAtom } from "../../atoms";
import { catBreedsSelector, dogBreedsSelector } from "../../selectors";

export const useReferenceData = () => {
  const petBreeds = useRecoilValue(petBreedsAtom);
  const suburbs = useRecoilValue(suburbsAtom);
  const catBreeds = useRecoilValueLoadable(catBreedsSelector);
  const dogBreeds = useRecoilValueLoadable(dogBreedsSelector);
  const insuranceCompanies = useRecoilValue(petInsuranceCompaniesAtom);
  const westpacPublicKey = useRecoilValue(westpacPublicKeyAtom);

  return {
    petBreeds,
    suburbs,
    catBreeds,
    dogBreeds,
    insuranceCompanies,
    westpacPublicKey,
  };
};

export default useReferenceData;
