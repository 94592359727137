import { Button, Grid } from "@mui/material";
import { RacwaStepperPageTitle } from "@racwa/react-components";
import { AddressInput, NameInput, ToggleButtonGroup, YesNo } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import useFindAddress from "../../shared/hooks/useFindAddress";
import { PurchasePageHeadings } from "../../shared/routing/routes.config";
import CurrentInsurerInput from "./components/CurrentInsurerInput";
import { VetAndInsuranceFormProps } from "./types";

export const VetAndInsuranceForm = ({ form, onSubmit, petName, data }: VetAndInsuranceFormProps) => {
  const { handleSubmit, control } = form;
  const hasVetWatch = useWatch({ control, name: "hasVet" });
  const hasExistingInsurance = useWatch({ control, name: "existingInsurance" });

  const { onAddressInputChange, onAddressSelection, addressResults } = useFindAddress();

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RacwaStepperPageTitle
              title={petName ? "Just two more things about " + petName : PurchasePageHeadings.VetAndInsurance}
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label={`Does ${petName ? petName : "your pet"} have a regular vet?`}
              id="hasVet"
              name="hasVet"
              options={YesNo}
            />
          </Grid>
          {hasVetWatch === YesNo.Yes && (
            <>
              <Grid item xs={12}>
                <NameInput
                  autoCompleteCategory=""
                  maxLength={30}
                  inputId="vetClinicName"
                  fieldName="vetClinicName"
                  fieldLabel="Vet clinic name"
                  placeholderText="e.g. John's Practice"
                />
              </Grid>
              <Grid item xs={12}>
                <AddressInput
                  label="Vet address"
                  name="vetAddress"
                  data={addressResults}
                  onInputChange={onAddressInputChange}
                  onSelection={onAddressSelection}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ToggleButtonGroup
              label={`Is ${petName ? petName : "your pet"} currently insured?`}
              id="existingInsurance"
              name="existingInsurance"
              options={YesNo}
            />
          </Grid>
          {hasExistingInsurance === YesNo.Yes && (
            <Grid item xs={12}>
              <CurrentInsurerInput id="currentInsurer" options={data.insuranceCompanies} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained" fullWidth sx={{ marginTop: "16px" }}>
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default VetAndInsuranceForm;
