import { Grid, Link, Typography } from "@mui/material";
import { ContactNumberInput, DateOfBirthInput, EmailInput, FirstNameInput } from "raci-react-library";

// TODO: Move this to RRL as a MemberMatch Composite at a later point after PoC is finished
// Integrate this component with the SKipLink and Find my Details button
export const MemberMatch = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FirstNameInput id="firstName" />
      </Grid>
      <Grid item xs={12}>
        <DateOfBirthInput label="Date of Birth" id="dateOfBirth" />
      </Grid>
      <Grid item xs={12}>
        <ContactNumberInput isMobile name="mobileNumber" />
      </Grid>
      <Grid item xs={12}>
        <EmailInput id="email" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ marginTop: "8px", marginBottom: "8px", "& a": { fontWeight: 500 } }}>
          See our{" "}
          <Link
            id="privacy-policy-link"
            href={process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE}
            target="_blank"
            rel="noopener noreferrer"
            component="a"
            color="primary"
          >
            privacy policy
          </Link>{" "}
          and{" "}
          <Link
            id="pds-link"
            href={process.env.REACT_APP_RAC_PDS_PED_URL}
            target="_blank"
            rel="noopener noreferrer"
            component="a"
            color="primary"
          >
            PDS
          </Link>{" "}
          for why we collect, and how we handle and protect, your personal information.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MemberMatch;
