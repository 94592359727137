import { Grid, Link } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import React from "react";
import { useFormContext } from "react-hook-form";
import { MemberDetailsState, YesNoSkip } from "../../types";

const id = `link-${YesNoSkip.Skip}`;

export interface SkipLinkProps {
  disabled?: boolean;
}

export const SkipLink = ({ disabled }: SkipLinkProps) => {
  const { setValue } = useFormContext<MemberDetailsState>();
  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <Link
        id={id}
        name={YesNoSkip.Skip}
        component="button"
        type="button"
        role="button"
        variant="body2"
        color="primary"
        onClick={async (e: React.SyntheticEvent<Element, Event>) => {
          e.preventDefault();
          gtm(fieldTouched("Skip Link"));
          setValue("areYouAnRacMember", YesNoSkip.Skip);
        }}
        disabled={disabled}
      >
        Skip {">"}
      </Link>
    </Grid>
  );
};

export default SkipLink;
