import {
  AllReferenceDataTypePetTypes,
  NewBusinessPetApiException,
  RetrieveQuoteResponse,
} from "raci-new-business-pet-clientproxy";
import {
  HTTP_STATUS_CODE_NOT_FOUND,
  HTTP_STATUS_CODE_TOO_MANY_REQUESTS,
  PaymentFrequency,
  Suburb as RRLSuburb,
  YesNo,
  useBackdrop,
  useSessionState,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import useQuoteSession from "../../../../shared/hooks/useQuoteSession";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import {
  PurchaseRoutes,
  QUOTE_YOUR_QUOTE_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { AboutYouState } from "../../../AboutYou/types";
import { AboutYourPetState } from "../../../AboutYourPet/types";
import { MemberDetailsState, YesNoSkip } from "../../../MemberDetails/types";
import { MoreAboutYourPetState, PetGender } from "../../../MoreAboutYourPet/types";
import { StartDateState } from "../../../StartDate/types";
import { VetAndInsuranceState } from "../../../VetAndInsurance/types";
import { YourDetailsState } from "../../../YourDetails/types";
import { YourPetState } from "../../../YourPet/types";
import { YourQuoteState } from "../../../YourQuote/types";
import { QuoteMatchAttempt, RetrieveQuoteFormProps, RetrieveQuoteState } from "../../types";

export const useRetrieveQuote = (): RetrieveQuoteFormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [backdrop, setBackdrop] = useBackdrop();
  const [, setQuoteNotFound] = useState(false);
  const [quoteMatchAttempt, setQuoteMatchAttempt] = useState(QuoteMatchAttempt.NoAttempt);
  const [processingQuote, setProcessingQuote] = useState(false);
  const [searchParams] = useSearchParams();
  const queryParamQuoteNumber = searchParams.get("quoteNumber");
  const { suburbs, petBreeds, insuranceCompanies } = useReferenceData();
  const rrlSuburbs = suburbs?.filter((suburb): suburb is RRLSuburb => !!suburb?.cityName) ?? [];
  const [sessionState] = useSessionState<RetrieveQuoteState>();
  const [, setMemberDetailsState] = useSessionState<MemberDetailsState>({ specificKey: QuoteRoutes.MemberDetails });
  const [, setYourPetState] = useSessionState<YourPetState>({ specificKey: QuoteRoutes.YourPet });
  const [, setAboutYourPetState] = useSessionState<AboutYourPetState>({ specificKey: QuoteRoutes.AboutYourPet });
  const [, setAboutYouState] = useSessionState<AboutYouState>({ specificKey: QuoteRoutes.AboutYou });
  const [, setYourQuoteState] = useSessionState<YourQuoteState>({ specificKey: QuoteRoutes.YourQuote });
  const [, setStartDateState] = useSessionState<StartDateState>({ specificKey: PurchaseRoutes.StartDate });
  const [, setMoreAboutYourPetState] = useSessionState<MoreAboutYourPetState>({
    specificKey: PurchaseRoutes.MoreAboutYourPet,
  });
  const [, setVetAndInsuranceState] = useSessionState<VetAndInsuranceState>({
    specificKey: PurchaseRoutes.VetAndInsurance,
  });
  const [, setYourDetailsState] = useSessionState<YourDetailsState>({ specificKey: PurchaseRoutes.YourDetails });
  const [, setQuoteState] = useQuoteSession();

  const form = useForm<RetrieveQuoteState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      ...sessionState,
      quoteNumber: queryParamQuoteNumber ?? sessionState.quoteNumber,
    },
  });

  useEffect(() => {
    if (
      (suburbs.length === 0 || petBreeds.length === 0 || insuranceCompanies.length === 0) &&
      !backdrop.open &&
      !processingQuote
    ) {
      setBackdrop(true);
    }

    if (
      suburbs.length > 0 &&
      petBreeds.length > 0 &&
      insuranceCompanies.length > 0 &&
      backdrop.open &&
      !processingQuote
    ) {
      setLoaded(true);
      setBackdrop(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suburbs, petBreeds, insuranceCompanies, loaded, backdrop]);

  const getTitle = (title: string | undefined) => {
    if (title === undefined) {
      return undefined;
    } else {
      return title === "Doctor" ? "Dr" : title;
    }
  };

  const setSessionStates = (response: RetrieveQuoteResponse) => {
    setMemberDetailsState({
      areYouAnRacMember: YesNoSkip.Skip,
      isCompleted: true,
    });

    setYourPetState({
      petType:
        response.petDetails.petType === AllReferenceDataTypePetTypes.Dog
          ? AllReferenceDataTypePetTypes.Dog
          : AllReferenceDataTypePetTypes.Cat,
      petName: response.petDetails.petName,
      petBreed: petBreeds.find((breed) => breed.id === response.petDetails.petBreed) ?? petBreeds[0],
      petPurpose: YesNo.No,
      ownedByBusinessOrTrust: YesNo.No,
      isCompleted: true,
    });

    setAboutYourPetState({
      petDateOfBirth: new Date(response.petDetails.petDateOfBirth),
      preExistingIllnessOrInjuryToggle: YesNo.No,
      suburb: rrlSuburbs.find((suburb) => suburb.cityName === response.petDetails.petSuburb) ?? rrlSuburbs[0],
      isCompleted: true,
    });

    setAboutYouState({
      threeYearPolicyIssue: YesNo.No,
      criminalOffence: YesNo.No,
      dateOfBirth: new Date(response.policyholderDetails.dateOfBirth),
      isCompleted: true,
    });

    setQuoteState(response.quoteDetails);
    setYourQuoteState({
      tlcCover: response.quoteDetails.hasTlcCover ? YesNo.Yes : YesNo.No,
      excess: response.quoteDetails.excess,
      paymentFrequency: PaymentFrequency.Annual,
    });

    // If an email and contact number are returned, we can assume that they have completed the YourDetails page
    // We can also assume the pet's gender, as this means that page has also been completed
    if (response.isCompletedQuote) {
      setStartDateState({
        startDate: new Date(response.startDate),
      });

      setVetAndInsuranceState({
        hasVet: response.petDetails.vetClinicName ? YesNo.Yes : YesNo.No,
        vetClinicName: response.petDetails.vetClinicName,
        existingInsurance: response.petDetails.currentInsurer ? YesNo.Yes : YesNo.No,
        currentInsurer:
          insuranceCompanies.find((company) => company.externalCode === response.petDetails.currentInsurer) ??
          undefined,
      });

      setMoreAboutYourPetState({
        petGender: response.petDetails.petGender === PetGender.Male ? PetGender.Male : PetGender.Female,
        petSterilised: response.petDetails.isSterilised ? YesNo.Yes : YesNo.No,
      });

      setYourDetailsState({
        firstName: response.policyholderDetails.firstName ?? undefined,
        title: getTitle(response.policyholderDetails.title),
        gender: response.policyholderDetails.gender ?? undefined,
        lastName: response.policyholderDetails.lastName ?? undefined,
        contactNumber: response.policyholderDetails.contactNumber ?? undefined,
        email: response.policyholderDetails.email ?? undefined,
        mailingAddress: undefined,
      });
    }
  };

  const onSubmit = async (newValues: RetrieveQuoteState) => {
    try {
      setBackdrop(true);
      setProcessingQuote(true);
      const response = await apiClient.retrieveQuote(newValues.quoteNumber, newValues.suburb.name ?? "");
      setSessionStates(response.result);
      navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
    } catch (ex: unknown) {
      const exception = ex as NewBusinessPetApiException;
      if (exception.status === HTTP_STATUS_CODE_NOT_FOUND) {
        setQuoteMatchAttempt(QuoteMatchAttempt.NoMatch);
      } else if (exception.status === HTTP_STATUS_CODE_TOO_MANY_REQUESTS) {
        setQuoteMatchAttempt(QuoteMatchAttempt.TooManyAttempts);
      } else {
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
      }
    } finally {
      setBackdrop(false);
      setProcessingQuote(false);
    }
  };

  return {
    form,
    onSubmit,
    setQuoteNotFound,
    suburbs: rrlSuburbs,
    quoteMatchAttempt,
  };
};

export default useRetrieveQuote;
