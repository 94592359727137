import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface RouteGuardProps {
  disableChecks?: string[];
}

export const RouteGuard = ({ disableChecks = [] }: RouteGuardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();

  /**
   * The below will check if the current page can
   * be navigated to by checking if the previous
   * page has been completed.
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;
    if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
      navigate(previousPageUrl, { replace: true });
    }
  }, [navigate, disableChecks, previousPageUrl, isPreviousPageCompleted, location.pathname]);

  return <></>;
};

export default RouteGuard;
