import { useGetSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PurchaseRoutes, QuoteRoutes, UTILITY_SESSION_TIMEOUT_PAGE_URL } from "../../../shared/routing/routes.config";
import { PaymentData } from "../../Payment/types";
import { YourDetailsState } from "../../YourDetails/types";
import { YourPetState } from "../../YourPet/types";
import { FormProps } from "../types";

const PAGE_TIMEOUT_INTERVAL_MILLISECONDS = 3 * 60 * 1000; // 5 minutes

export const useConfirmation = (): FormProps => {
  const navigate = useNavigate();
  const yourPetState = useGetSessionState<YourPetState>(QuoteRoutes.YourPet);
  const paymentState = useGetSessionState<PaymentData>(PurchaseRoutes.Payment);
  const yourDetailsState = useGetSessionState<YourDetailsState>(PurchaseRoutes.YourDetails);

  useEffect(() => {
    const handle = setTimeout(() => {
      navigate(UTILITY_SESSION_TIMEOUT_PAGE_URL);
    }, PAGE_TIMEOUT_INTERVAL_MILLISECONDS);

    return () => {
      clearTimeout(handle);
    };
  }, [navigate]);

  return {
    isSuccessful: paymentState.isSuccessful ?? false,
    policyNumber: paymentState.policyNumber ?? "",
    firstName: yourDetailsState.firstName,
    receiptNumber: paymentState.receiptNumber,
    total: paymentState.total,
    petName: yourPetState.petName,
  };
};

export default useConfirmation;
