import { RacwaBreadcrumbDetails } from "@racwa/react-components";

const FRAGMENT = "#";

export enum Breadcrumb {
  HomeAndLife = "Home & Life",
  PetInsurance = "Pet insurance",
  Quote = "Quote",
}

type BreadcrumbLink = RacwaBreadcrumbDetails["links"][number];

const breadcrumbs: Record<Breadcrumb, BreadcrumbLink> = {
  [Breadcrumb.HomeAndLife]: {
    name: Breadcrumb.HomeAndLife,
    key: Breadcrumb.HomeAndLife,
    href: process.env.REACT_APP_RAC_HOME_AND_LIFE_PWS_URL ?? FRAGMENT,
  },
  [Breadcrumb.PetInsurance]: {
    name: Breadcrumb.PetInsurance,
    key: Breadcrumb.PetInsurance,
    href: process.env.REACT_APP_PET_INSURANCE_PWS_URL ?? FRAGMENT,
  },
  [Breadcrumb.Quote]: {
    name: Breadcrumb.Quote,
    key: Breadcrumb.Quote,
    href: FRAGMENT,
  },
};

export const getBreadcrumbs = (isActiveFlow: boolean): RacwaBreadcrumbDetails => {
  const links = [breadcrumbs[Breadcrumb.HomeAndLife], breadcrumbs[Breadcrumb.PetInsurance]];

  return {
    links,
    currentPage: breadcrumbs[Breadcrumb.Quote],
  };
};

export default getBreadcrumbs;
