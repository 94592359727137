import { useQuery } from "@tanstack/react-query";
import { HTTP_STATUS_CODE_OK, LoadingStatus, useSessionIdStorageKey, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { sessionIdAtom } from "../../atoms";
import useApiClient from "../useApiClient";

export const useCreateSession = () => {
  const client = useApiClient();
  const setBackdrop = useSetBackdrop();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const setSessionId = useSetRecoilState(sessionIdAtom);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const createSession = async () => {
    setIsError(false);
    const hasSessionId = sessionStorage.getItem(sessionIdStorageKey);
    try {
      setBackdrop(true);
      if (!hasSessionId) {
        const response = await client.createSession();
        sessionStorage.setItem(sessionIdStorageKey, response.result);
        setSessionId(response.result);
        if (response.status !== HTTP_STATUS_CODE_OK) {
          setIsError(true);
        }
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
      setBackdrop(false);
    }
  };

  useQuery({
    queryKey: ["sessionId"],
    queryFn: createSession,
    staleTime: Infinity,
  });

  return isLoading ? LoadingStatus.Loading : isError ? LoadingStatus.Error : LoadingStatus.Successful;
};

export default useCreateSession;
