import { Grid, styled, Typography } from "@mui/material";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "600px",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: "24px",
}));
