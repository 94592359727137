import { Button, Grid, Typography } from "@mui/material";
import { RacwaStepperPageTitle } from "@racwa/react-components";
import {
  InsurancePhoneNumberLink,
  KeyboardDateInput,
  NotificationCard,
  SuburbInput,
  ToggleButtonGroup,
  YesNo,
  useTooltip,
} from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { QuotePageHeadings } from "../../shared/routing/routes.config";
import { AboutYourPetFormProps } from "./types";

export const AboutYourPetForm = ({
  form,
  data,
  onSubmit,
  petName,
  minimumPetAge,
  maximumPetAge,
}: AboutYourPetFormProps) => {
  const { control, handleSubmit } = form;

  const illnessOrInjuryToggleWatch = useWatch({ control, name: "preExistingIllnessOrInjuryToggle" });

  const disabled = illnessOrInjuryToggleWatch === YesNo.Yes;

  const suburbTooltipProps = useTooltip({
    title: "Why are we asking this?",
    message: `We need to know where ${
      petName ? petName : "your pet"
    } resides so we can tailor your quote and give you the best possible premium. This information is used in conjunction with other information collected during this quote.`,
  });

  const dateOfBirthTooltipProps = {
    title: "Why are we asking this?",
    message: `${
      petName ? petName : "Your pet"
    }'s age is required so we can tailor your quote and give you the best possible premium. This information is used in conjunction with other information collected during this quote. In order to be eligible for pet insurance, ${
      petName ? petName : "your pet"
    } must be older than 8 weeks of age and less than 9 years of age at the start date of your first RAC Pet policy for ${
      petName ? petName : "this pet"
    }.`,
    showDialog: false,
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RacwaStepperPageTitle title={petName ? "Tell us more about " + petName : QuotePageHeadings.AboutYourPet} />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              label={`Does ${petName ? petName : "your pet"} have any pre-existing illness or injuries?`}
              id="preExistingIllnessOrInjuryToggle"
              name="preExistingIllnessOrInjuryToggle"
              options={YesNo}
            />
          </Grid>

          {illnessOrInjuryToggleWatch === YesNo.Yes && (
            <NotificationCard
              severity="error"
              message={{
                title: "We need some more information",
                content: (
                  <Typography variant="body2">
                    Please call <InsurancePhoneNumberLink id="insurancePhoneNumber" /> to finish your quote.
                  </Typography>
                ),
              }}
            />
          )}
          <Grid item xs={12} sx={{ paddingTop: "0px" }}>
            <KeyboardDateInput
              id="petDateOfBirth"
              name="petDateOfBirth"
              label={`What is ${petName ? petName : "your pet"}'s date of birth?`}
              tooltipProps={dateOfBirthTooltipProps}
              disabled={disabled}
              minDate={minimumPetAge}
              maxDate={maximumPetAge}
              errorMessage={`Please enter a valid date of birth for ${petName ? petName : "your pet"}`}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "0px" }}>
            <NotificationCard
              severity="info"
              message={{
                title: `${petName ? petName + "'s " : "Pet"} Age`,
                paragraphs: [
                  `${
                    petName ? petName : "Your pet"
                  } must be over 8 weeks old and under 9 years old at the start date of your first RAC Pet policy for ${
                    petName ? petName : "this pet"
                  }.`,
                  `If ${
                    petName ? petName + " is older" : "you have an older pet"
                  }, as long as they have insurance before they turn 9 and you renew it each year, we'll cover them for the rest of their life.`,
                  `It is ok to estimate ${petName ? petName : "your pet"}'s age.`,
                ],
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SuburbInput
              id="suburb"
              label={`Where does ${petName ? petName : "your pet"} live?`}
              options={data.suburbs ?? []}
              placeholder="Enter suburb"
              errorMessage="Please enter a suburb"
              tooltipProps={suburbTooltipProps}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: "16px" }}
              disabled={disabled}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AboutYourPetForm;
