import { format, parseISO } from "date-fns";
import {
  CreateQuoteRequest,
  QuoteResponsePremiumUpdateReason,
  UpdateQuoteRequest,
} from "raci-new-business-pet-clientproxy";
import { PaymentFrequency, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import useQuoteSession from "../../../../shared/hooks/useQuoteSession";
import {
  QUOTE_YOUR_QUOTE_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { AboutYourPetState } from "../../../AboutYourPet/types";
import { MemberDetailsState, YesNoSkip } from "../../../MemberDetails/types";
import { YourPetState } from "../../../YourPet/types";
import { YourQuoteState } from "../../../YourQuote/types";
import { AboutYouFormProps, AboutYouState } from "../../types";

export const useAboutYou = (): AboutYouFormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [sessionState, setSessionState] = useSessionState<AboutYouState>();
  const memberDetailsState = useGetSessionState<MemberDetailsState>(QuoteRoutes.MemberDetails);
  const yourPetState = useGetSessionState<YourPetState>(QuoteRoutes.YourPet);
  const aboutYourPetState = useGetSessionState<AboutYourPetState>(QuoteRoutes.AboutYourPet);
  const yourQuoteState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const [quoteState, setQuoteState] = useQuoteSession();
  const [showPremiumChangeDialog, setShowPremiumChangeDialog] = useState(false);
  let premiumUpdateReason = QuoteResponsePremiumUpdateReason.None;

  const form = useForm<AboutYouState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: { ...sessionState, dateOfBirth: sessionState.dateOfBirth && new Date(sessionState.dateOfBirth) },
  });

  const onSubmit = async (values: AboutYouState) => {
    try {
      setBackdrop(true);

      const createRequest: CreateQuoteRequest = {
        petType: yourPetState.petType,
        petBreed: yourPetState.petBreed.id,
        petName: yourPetState.petName,
        // @ts-ignore
        petDateOfBirth: format(parseISO(aboutYourPetState.petDateOfBirth), "yyyy-MM-dd"),
        petSuburb: aboutYourPetState.suburb.name,
      };

      if (memberDetailsState.areYouAnRacMember !== YesNoSkip.Yes) {
        // @ts-ignore
        createRequest.dateOfBirth = (values.dateOfBirth && format(values.dateOfBirth, "yyyy-MM-dd")) || null;
      }

      if (!quoteState.quoteNumber) {
        // @ts-ignore
        const response = await apiClient.createQuote(createRequest);

        setQuoteState({
          ...response.result,
          quoteNumber: response.result.quoteNumber ?? "",
        });

        setSessionState({ ...values, isCompleted: true });
        navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
      } else {
        const updateRequest: UpdateQuoteRequest = {
          baseQuoteDetails: {
            ...createRequest,
          },
        };
        const response = await apiClient.updateQuote(updateRequest);
        setQuoteState({ ...response.result });
        setSessionState({ ...values, isCompleted: true });

        if (response.result.hasPremiumUpdated) {
          setShowPremiumChangeDialog(true);
          premiumUpdateReason = response.result.premiumUpdateReason;
        } else {
          navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
        }
      }
    } catch (ex) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    onSubmit,
    isMember: memberDetailsState.areYouAnRacMember === YesNoSkip.Yes,
    showPremiumChangeDialog,
    setShowPremiumChangeDialog,
    premiumUpdateReason: premiumUpdateReason,
    redirectToNextPage: () => navigate(QUOTE_YOUR_QUOTE_PAGE_URL),
    paymentFrequency: yourQuoteState.paymentFrequency ?? PaymentFrequency.Annual,
    premiumDetails: quoteState.premium,
  };
};

export default useAboutYou;
